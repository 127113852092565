import React, {useEffect, useRef, useState} from "react";
import {Toast}                              from "primereact/toast";
import {InputText}                          from "primereact/inputtext";
import {Button}                             from "primereact/button";
import {confirmDialog}                      from "primereact/confirmdialog";
import {Dialog}                             from "primereact/dialog";
import {Dropdown}                           from "primereact/dropdown";
import {ProgressSpinner}                    from "primereact/progressspinner";

import api                from "../../services/api";
import {Obrigatorio}      from "../../styles/global";
import Tabela             from "../../components/tabela";
import {parametrosPadrao} from "../../configs/constantes";
import {IdUsuarioLogado}  from "../../services/auth";
import debug              from "../../utils/debug";
import TplStatus          from "../../components/tplStatus";
import {Checkbox} from "primereact/checkbox";
import {InputNumber} from "primereact/inputnumber";


const Usuario = () => {
    const classe = 'Usuário';
    const tabela = 'usuarios';
    const endpointAPI = 'seguranca/usuario';
    const inicial = {
        id: 0,
        nome: '',
        idcliente: 0,
        email: '',
        idperfil: 0,
        idloja: null,
        funcionario: 0,
        senha: '',
        confirmaSenha: '',
        fone: '',
        mudarSenha: false,
        tipopagamentoferiado: null,
        valorfixoferiado: null,
        tipopagamentodomingo: null,
        valorfixodomingo: null
    };
    const aviso = useRef(null);
    const [item, setItem] = useState(inicial);
    const [tituloTela, setTituloTela] = useState('');
    const [exibeTela, setExibirTela] = useState(false);
    const [listar, setListar] = useState(false);
    const [listaCliente, setListaCliente] = useState([]);
    const [listaPerfil, setListaPerfil] = useState([]);
    const [listaLoja, setListaLoja] = useState([]);
    const [listaTipoPagamentoFeriado, setListaTipoPagamentoFeriado] = useState([]);
    const [listaTipoPagamentoDomingo, setListaTipoPagamentoDomingo] = useState([]);
    const [permissoesTela, setPermissoesTela] = useState(null);
    const [parametrosTela, setParametrosTela] = useState(parametrosPadrao);
    const [loadingFormulario, setLoadingFormulario] = useState(true);
    const [exibirValorFixoFeriado, setExibirValorFixoFeriado] = useState(false);
    const [exibirValorFixoDomingo, setExibirValorFixoDomingo] = useState(false);

    const aoAbrirFormulario = (registro) => {
        setItem(inicial);
        setLoadingFormulario(true);
        if (registro.id) {
            new api().requisitar('get', `${endpointAPI}/${registro.id}`).then((resposta) => {
                setItem(resposta);
                if(resposta.apresentarvalorfixodomingo){
                    setExibirValorFixoDomingo(true);
                }
                if(resposta.apresentarvalorfixoferiado){
                    setExibirValorFixoFeriado(true);
                }
                setLoadingFormulario(false);
            }).catch((erro) => {
                debug(erro);
                aviso.current.show({severity: 'error', summary: 'Erro', detail: (erro.response && erro.response.data && erro.response.data.error_description) ? erro.response.data.error_description : 'Ocorreu um erro não tratado', life: 3000});
            });
            setTituloTela(`Alterar ${classe}`);
        } else {
            setTituloTela(`Cadastro de ${classe}`);
            setLoadingFormulario(false);
        }
        setExibirTela(true);
    };

    const aoListar = () => setListar(false);

    const limparTela = () => {
        setItem(inicial);
        setExibirTela(false);
        setListar(true);
    };

    const reativar = async (id) => {
        await new api().requisitar('PUT', `${endpointAPI}/reativar/${id}`).then((resposta) => {
            aviso.current.show({severity: 'success', summary: 'Confirmação', detail: resposta.mensagem, life: 3000});
            limparTela();
        }).catch((erro) => {
            debug(erro);
            aviso.current.show({severity: 'error', summary: 'Erro', detail: (erro.response && erro.response.data && erro.response.data.error_description) ? erro.response.data.error_description : 'Ocorreu um erro não tratado', life: 3000});
        });
    };

    const aoReativarItem = (id) => {
        confirmDialog({
                          message    : `Confirma reativação do ${classe}?`,
                          header     : 'Atenção',
                          icon       : 'pi pi-exclamation-triangle',
                          acceptLabel: 'Sim',
                          rejectLabel: 'Não',
                          accept     : () => {
                              reativar(id);
                          },
                      });
    };

    const excluir = async (id) => {
        await new api().requisitar('DELETE', `${endpointAPI}/${id}`).then((resposta) => {
            aviso.current.show({severity: 'success', summary: 'Confirmação', detail: resposta.mensagem, life: 3000});
            limparTela();
        }).catch((erro) => {
            debug(erro);
            aviso.current.show({severity: 'error', summary: 'Erro', detail: (erro.response && erro.response.data && erro.response.data.error_description) ? erro.response.data.error_description : 'Ocorreu um erro não tratado', life: 3000});
        });
    };

    const aoExcluirItem = (id) => {
        confirmDialog({
                          message    : `Confirma exclusão do ${classe}?`,
                          header     : 'Atenção',
                          icon       : 'pi pi-exclamation-triangle',
                          acceptLabel: 'Sim',
                          rejectLabel: 'Não',
                          accept     : () => {
                              excluir(id);
                          },
                      });
    };

    const aoSalvar = async () => {
        try {
            if (item.senha && item.senha != item.confirmaSenha) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Senha não confere na confirmação!'};
            }
            if (!item.idperfil) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe o perfil de acesso!'};
            }
            if (!item.idcliente) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe o cliente ao qual o usuário está vinculado!'};
            }
            if (!item.email) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe o email!'};
            }
            if (!item.nome) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe o nome!'};
            }

            const _item = item;
            if(!exibirValorFixoFeriado || _item.valorfixoferiado === 0) {
                _item.valorfixoferiado = null;
            }
            if(!exibirValorFixoDomingo  || _item.valorfixodomingo === 0) {
                _item.valorfixodomingo = null;
            }

            await new api().requisitar((item.id > 0) ? 'put' : 'post', (item.id > 0 ? `${endpointAPI}/${item.id ?? ''}` : endpointAPI), _item).then((resposta) => {
                aviso.current.show({severity: 'success', summary: 'Confirmação', detail: resposta.mensagem, life: 3000});
                limparTela();
            }).catch((erro) => {
                throw {tipo: 'error', titulo: 'Falha!!', mensagem: erro.response.data ?? 'Ocorreu um erro não tratado'};
            });

        } catch (erro) {
            aviso.current.show({severity: erro.tipo ?? 'error', summary: erro.titulo ?? 'Erro', detail: erro.mensagem ?? erro, life: 3000});
        }
    };

    const aoDigitar = (e, propriedade) => {
        let _item = {...item};
        const texto = (e.target && e.target.value) || e.value || '';
        _item[`${propriedade}`] = texto;
        setItem(_item);

        if(propriedade === 'tipopagamentoferiado') {
            const tipo = listaTipoPagamentoFeriado.find(item => item.id === texto);
            if(tipo && tipo.valorFixo){
                setExibirValorFixoFeriado(true);
            } else {
                setExibirValorFixoFeriado(false);
            }
        } else if (propriedade === 'tipopagamentodomingo') {
            const tipo = listaTipoPagamentoDomingo.find(item => item.id === texto);
            if(tipo && tipo.valorFixo){
                setExibirValorFixoDomingo(true);
            } else {
                setExibirValorFixoDomingo(false);
            }
        }
    };

    const aoSelecionar = (e, propriedade) => {
        let _item = {...item};
        if (e.checked) {
            _item[`${propriedade}`] = 1;
        }
        else {
            _item[`${propriedade}`] = 0;
        }
        setItem(_item);
    }

    const aoEnter = (e) => {
        if (e.key === 'Enter') {
            aoSalvar(e);
        }
    };

    useEffect((e) => {
        new api().requisitar('get', `configuracao/tela/usuario/permissoes`).then((resposta) => {
            setPermissoesTela(resposta);
            const parametros = {
                pesquisarTabela  : true,
                nomeClasse       : classe,
                tabela           : tabela,
                caminhoBreadcrumb: [{label: 'Segurança'}, {label: 'Usuários'}],
                endpointApi      : endpointAPI,
                desativar        : true,
                tabelaDados      : {
                    reordenar              : false,
                    multiplaSelecao        : true,
                    apresentarDados        : resposta.listar ?? false,
                    botaoAdicionar         : resposta.cadastrar ?? false,
                    botaoExcluir           : resposta.excluir ?? false,
                    botaoEditarRegistro    : resposta.alterar ?? false,
                    botaoExcluirRegistro   : resposta.excluir ?? false,
                    botaoAuditoriaRegistro : resposta.auditoria ?? false,
                    botaoVisualizarRegistro: resposta.visualizar ?? false,
                    colunas                : [
                        {legenda: 'Cliente', campo: 'cliente', ordenavel: true},
                        {legenda: 'Perfil', campo: 'perfil', ordenavel: true},
                        {legenda: 'Loja', campo: 'loja', ordenavel: true},
                        {legenda: 'Nome', campo: 'nome', ordenavel: true},
                        {legenda: 'É Funcionário', campo: 'funcionario', ordenavel: true},
                        {legenda: 'Status', campo: 'status', ordenavel: true, template: TplStatus}
                    ]
                },
                abrirFormulario  : aoAbrirFormulario,
                finalizarPesquisa: aoListar
            }
            setParametrosTela(parametros);
        }).catch((erro) => {
            throw {tipo: 'error', titulo: 'Falha!!', mensagem: erro.response.data ?? 'Ocorreu um erro não tratado'};
        });

        new api().requisitar('get', `/precadastro/cliente/combo`).then((resposta) => {
            setListaCliente(resposta ?? []);
        }).catch((erro) => {
            debug(erro);
        });

        new api().requisitar('get', `/seguranca/perfil/combo`).then((resposta) => {
            setListaPerfil(resposta ?? []);
        }).catch((erro) => {
            debug(erro);
        });

        new api().requisitar('get', `/cadastro/loja/combo`).then((resposta) => {
            setListaLoja(resposta ?? []);
        }).catch((erro) => {
            debug(erro);
        });

        new api().requisitar('get', `/seguranca/usuario/tipos-pagamento-feriado`).then((resposta) => {
            setListaTipoPagamentoFeriado(resposta ?? []);
        }).catch((erro) => {
            debug(erro);
        });

        new api().requisitar('get', `/seguranca/usuario/tipos-pagamento-domingo`).then((resposta) => {
            setListaTipoPagamentoDomingo(resposta ?? []);
        }).catch((erro) => {
            debug(erro);
        });

    }, []);

    const botoesAcaoTela = () => {
        return (<React.Fragment>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={(e) => setExibirTela(false)}/>
            {item.id > 0 && item.status > 0 && permissoesTela.excluir && <Button label="Desativar" icon="pi pi-trash" className="p-button-text" onClick={(e) => aoExcluirItem(item.id)}/>}
            {item.id > 0 && item.status < 0 && permissoesTela.reativar && <Button label="Reativar" icon="pi pi-undo" className="p-button-text" onClick={(e) => aoReativarItem(item.id)}/>}
            {((item.id > 0 && permissoesTela.alterar) || permissoesTela.cadastrar) && <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={aoSalvar}/>}
        </React.Fragment>);
    };

    return (
        <Tabela parametros={parametrosTela} listar={listar}>
            <Toast ref={aviso}></Toast>
            <Dialog className='fluid' header={tituloTela} style={{maxWidth: '90%', width: '800px'}} footer={botoesAcaoTela} modal={true} visible={exibeTela} onHide={(e) => setExibirTela(false)}>
                {loadingFormulario ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '250px' }}>
                        <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                    </div>
                ) : (
                    <>
                        <div className="grid formgrid">
                            <div className="field col-12 mt-3">
                                <label htmlFor="idcliente">Cliente<Obrigatorio>*</Obrigatorio> </label><br/>
                                <Dropdown className="w-full" autoComplete="none" id="idcliente" value={item.idcliente} options={listaCliente} onChange={(e) => aoDigitar(e, 'idcliente')} optionLabel="valor" optionValue="id" required/>
                            </div>
                            <div className="field col-12 mt-3">
                                <label htmlFor="idperfil">Perfil<Obrigatorio>*</Obrigatorio> </label><br/>
                                <Dropdown className="w-full" autoComplete="none" id="idperfil" value={item.idperfil} options={listaPerfil} onChange={(e) => aoDigitar(e, 'idperfil')} optionLabel="valor" optionValue="id" required/>
                            </div>
                            <div className="flex col-12 mt-3 align-items-center">
                                <div className="field col-8 p-0">
                                    <label htmlFor="idloja">Loja</label><br/>
                                    <Dropdown className="w-full" autoComplete="none" id="idloja" value={item.idloja} options={listaLoja} onChange={(e) => aoDigitar(e, 'idloja')} optionLabel="valor" optionValue="id" required/>
                                </div>
                                <div className="flex field col-2 mt-auto ml-5">
                                    <Checkbox inputId="funcionario" name="funcionario" value="1" onChange={(e) => aoSelecionar(e, 'funcionario')} checked={item.funcionario === 1} />
                                    <label htmlFor="funcionario" className="m-0 ml-2 text-lg">Funcionário</label>
                                </div>
                            </div>
                            <div className="field col-12">
                                <label htmlFor="nome">Nome<Obrigatorio>*</Obrigatorio> </label><br/>
                                <InputText className="w-full" autoComplete="none" id="nome" maxLength={50} value={item.nome} onChange={(e) => aoDigitar(e, 'nome')} required/>
                            </div>
                            <div className="field col-12">
                                <label htmlFor="email">E-mail<Obrigatorio>*</Obrigatorio> </label><br/>
                                <InputText className="w-full" autoComplete="none" id="email" maxLength={50} value={item.email} onChange={(e) => aoDigitar(e, 'email')} required/>
                            </div>
                            {(!item.id || item.id == IdUsuarioLogado()) &&
                                <>
                                    <div className="field col-6">
                                        <label htmlFor="senha">Senha<Obrigatorio>*</Obrigatorio></label><br/>
                                        <InputText className="w-full" autoComplete="off" type="password" id="senha" maxLength={50} value={item.senha} onChange={(e) => aoDigitar(e, 'senha')} required/>
                                    </div>
                                    <div className="field col-6">
                                        <label htmlFor="csenha">Confirmação de senha<Obrigatorio>*</Obrigatorio></label><br/>
                                        <InputText className="w-full" autoComplete="off" type="password" id="csenha" maxLength={50} value={item.confirmaSenha} onChange={(e) => aoDigitar(e, 'confirmaSenha')} required/>
                                    </div>
                                </>
                            }
                            <div className="flex col-12 p-0 mt-3">
                                <div className="field col-6">
                                    <label htmlFor="idloja">Tipo Pagamento Feriado</label><br/>
                                    <Dropdown
                                        className="w-full"
                                        autoComplete="none"
                                        id="tipopagamentoferiado"
                                        value={item.tipopagamentoferiado}
                                        options={listaTipoPagamentoFeriado}
                                        onChange={(e) => aoDigitar(e, 'tipopagamentoferiado')}
                                        optionLabel="valor"
                                        optionValue="id"
                                    />
                                </div>
                                {
                                    exibirValorFixoFeriado ? (
                                        <div className="field col-6">
                                            <label htmlFor="valorfixoferiado">Valor Fixo Feriado</label><br />
                                            <InputNumber
                                                inputId="valorfixoferiado"
                                                value={item.valorfixoferiado}
                                                onChange={(e) => aoDigitar(e, 'valorfixoferiado')}
                                                mode="currency"
                                                currency="BRL"
                                                locale="pt-BR"
                                            />
                                        </div>
                                    ) : null
                                }
                            </div>
                            <div className="flex col-12 p-0 mt-3">
                                <div className="field col-6">
                                    <label htmlFor="idloja">Tipo Pagamento Domingo</label><br/>
                                    <Dropdown
                                        className="w-full"
                                        autoComplete="none"
                                        id="tipopagamentodomingo"
                                        value={item.tipopagamentodomingo}
                                        options={listaTipoPagamentoDomingo}
                                        onChange={(e) => aoDigitar(e, 'tipopagamentodomingo')}
                                        optionLabel="valor"
                                        optionValue="id"
                                    />
                                </div>
                                {
                                    exibirValorFixoDomingo ? (
                                        <div className="field col-6">
                                            <label htmlFor="valorfixodomingo">Valor Fixo Domingo</label><br />
                                            <InputNumber
                                                inputId="valorfixodomingo"
                                                value={item.valorfixodomingo}
                                                onChange={(e) => aoDigitar(e, 'valorfixodomingo')}
                                                mode="currency"
                                                currency="BRL"
                                                locale="pt-BR"
                                            />
                                        </div>
                                    ) : null
                                }
                            </div>
                        </div>
                    </>
                )}
            </Dialog>
        </Tabela>
    );
}

export default Usuario;
