import React, {useEffect, useRef, useState} from "react";
import {Toast}                              from "primereact/toast";
import {Button}                             from "primereact/button";
import {confirmDialog}                      from "primereact/confirmdialog";
import {Dialog}                             from "primereact/dialog";
import { ProgressSpinner }                  from 'primereact/progressspinner';
import {InputNumber}                        from "primereact/inputnumber";
import {Calendar}                           from  "primereact/calendar";
import {Dropdown}                           from "primereact/dropdown";
import {Tooltip}                            from "primereact/tooltip";
import {DataTable}                          from "primereact/datatable";
import {Column}                             from "primereact/column";
import {ColumnGroup}                        from "primereact/columngroup";
import {Row}                                from "primereact/row";
import {InputText}                          from "primereact/inputtext";

import api                from "../../services/api";
import {Obrigatorio}      from "../../styles/global";
import Tabela             from "../../components/tabela";
import {parametrosPadrao} from "../../configs/constantes";
import debug              from "../../utils/debug";

const Venda = () => {
    const classe = 'Venda';
    const tabela = 'venda';
    const endpointAPI = 'vendas/venda';
    const endpointRelatorioAPI = 'relatorios/relatorio';
    const aviso = useRef(null);
    const inicial = {id: 0, idloja: null, idformapagamento: null, qtdpecas: null, valor: null, data: null, datacancelamento: null, motivocancelamento: null};
    const [item, setItem] = useState(inicial);

    const [tituloTela, setTituloTela] = useState('');
    const [exibeTela, setExibirTela] = useState(false);
    const [listar, setListar] = useState(false);
    const [permissoesTela, setPermissoesTela] = useState(null);
    const [parametrosTela, setParametrosTela] = useState(parametrosPadrao);
    const [lojaLista, setLojaLista] = useState([]);
    const [formasPagamentoLista, setFormasPagamentoLista] = useState([]);
    const [formasPagamentoGeralLista, setFormasPagamentoGeralLista] = useState([]);
    const [loadingFormulario, setLoadingFormulario] = useState(true);
    const [desabilitarFormas, setDesabilitarFormas] = useState(true);

    const relatorioInicial = {idloja: null, idformapagamento: null, periodo_inicial: null, periodo_final: null}
    const relatorioVendaPontoInicial = {idloja: null, periodo_inicial: null, periodo_final: null}
    const [relatorio, setRelatorio] = useState(relatorioInicial);
    const [relatorioVendaPonto, setRelatorioVendaPonto] = useState(relatorioVendaPontoInicial);

    const [exibirGeracaoRelatorio, setExibirGeracaoRelatorio] = useState(false);
    const [exibirGeracaoRelatorioVendaPonto, setExibirGeracaoRelatorioVendaPonto] = useState(false);
    const [exibirRelatorioVenda, setExibirRelatorioVenda] = useState(false);
    const [exibirRelatorioVendaPonto, setExibirRelatorioVendaPonto] = useState(false);

    const relatorioVendaParamentrosInicial = {colunas: [], dados: [], totais: {}};
    const relatorioVendaPontoParamentrosInicial = {colunas: [], dados: []};
    const [relatorioVendaParamentros, setRelatorioVendaParamentros] = useState(relatorioVendaParamentrosInicial);
    const [relatorioVendaPontoParamentros, setRelatorioVendaPontoParamentros] = useState(relatorioVendaPontoParamentrosInicial);

    const [exibirCancelamento, setExibirCancelmento] =  useState(false);
    const cancelamentoInicial = {id: 0, motivocancelamento: null}
    const [cancelamento, setCancelamento] = useState(cancelamentoInicial)

    useEffect((e) => {
        new api().requisitar('get', `configuracao/tela/venda/permissoes`).then((resposta) => {
            setPermissoesTela(resposta);
            const parametros = {
                pesquisarTabela  : true,
                artigo:            'a',
                nomeClasse       : classe,
                tabela           : tabela,
                caminhoBreadcrumb: [{label: 'Vendas'}, {label: 'Registro de Venda'}],
                endpointApi      : endpointAPI,
                excluirRegistro  : true,
                tabelaDados      : {
                    reordenar              : false,
                    multiplaSelecao        : true,
                    apresentarDados        : true,
                    botaoAdicionar         : resposta.cadastrar ?? false,
                    botaoExcluir           : resposta.excluir ?? false,
                    botaoEditarRegistro    : resposta.alterar ?? false,
                    botaoExcluirRegistro   : resposta.excluir ?? false,
                    botaoAuditoriaRegistro : resposta.auditoria ?? false,
                    botaoVisualizarRegistro: resposta.visualizar ?? false,
                    botaoCustomizavel      : resposta.relatorio ?
                        <>
                            <Button label="Gerar Relatório de Vendas" icon="pi pi-plus" className="p-button-info mt-2 mr-2" onClick={() => aoAbirGeracaoRelatorio()}/>
                            <Button label="Relatório de Ponto x Última Venda" icon="pi pi-plus" className="p-button-info mt-2 mr-2" onClick={() => aoAbirGeracaoRelatorioPontoVenda()}/>
                        </>
                        : null,
                    filtroStatus           : false,
                    toleranciaEdicao       : true, // POSSIBILITA USUÁRIO A DELETAR E EDITAR REGISTROS DENTRO DE 5m APÓS CRIAR ITEM
                    apresentarCampos       : resposta.listar, // PERMITE APRESENTAÇÃO DE CAMPOS SENSÍVEIS
                    esconderCampos         : ['qtd_pecas', 'valor'], // ESCONDE CAMPOS DE USUÁRIOS SEM LISTAGEM
                    colunas                : [
                        {legenda: 'Data', campo: 'data', ordenavel: true},
                        {legenda: 'Loja', campo: 'loja_nome', ordenavel: true},
                        {legenda: 'Forma Pagamento', campo: 'forma_pagamento', ordenavel: true},
                        {legenda: 'Quantidade Peças', campo: 'qtd_pecas', ordenavel: true},
                        {legenda: 'Valor', campo: 'valor', ordenavel: true},
                        {legenda: 'Situação', campo: 'situacao', ordenavel: true},
                    ]
                },
                abrirFormulario  : aoAbrirFormulario,
                finalizarPesquisa: aoListar
            }
            setParametrosTela(parametros);
        }).catch((erro) => {
            throw  {tipo: 'error', titulo: 'Falha!!', mensagem: erro.response.data ?? 'Ocorreu um erro não tratado'};
        });

        new api().requisitar('get', 'cadastro/loja/combo').then((resposta) => {
            setLojaLista(resposta);
        }).catch((erro) => {
            debug(erro);
        });

        new api().requisitar('get', 'vendas/formas-pagamento/combo').then((resposta) => {
            setFormasPagamentoGeralLista(resposta);
        }).catch((erro) => {
            debug(erro);
        });

    }, []);

    const carregarFormaPagamento = (idloja) => {
        setDesabilitarFormas(true);
        new api().requisitar('get', `vendas/formas-pagamento/combo/${idloja}`).then((resposta) => {
            setFormasPagamentoLista(resposta);
            setDesabilitarFormas(false);
        }).catch((erro) => {
            debug(erro);
        });
    }

    const formatarData = (data) => {
        const [parteData, parteHora] = data.split(' ');
        const [ano, mes, dia] = parteData.split('-');
        const [horas, minutos, segundos] = parteHora.split(':');
        return new Date(ano, mes - 1, dia, horas, minutos, segundos);
    };

    const verificaUsuarioLoja = async () => {
        try {
            const loja = await new api().requisitar('get', 'cadastro/loja/usuario-loja');
            let _item = item;
            if (loja['id'] !== false) {
                setLojaLista([loja]);
                _item.idloja = loja['id'];
                setItem(_item);
                await carregarFormaPagamento(loja['id']);
                setDesabilitarFormas(false);
            }
        } catch (erro) {
            debug(erro);
        }
    }

    const aoAbrirFormulario = async (registro) => {
        setItem(inicial);
        setExibirTela(true);
        setLoadingFormulario(true);
        setDesabilitarFormas(true);
        if (registro.id) {
            new api().requisitar('get', `${endpointAPI}/${registro.id}`).then(async (resposta) => {
                resposta.data = formatarData(resposta.data);
                setItem(resposta);
                await carregarFormaPagamento(resposta.idloja);
                setDesabilitarFormas(false);
                setLoadingFormulario(false);
            }).catch((erro) => {
                debug(erro);
            })
            setTituloTela(`Alterar ${classe}`);
        } else {
            setTituloTela(`Cadastro de ${classe}`);
            await verificaUsuarioLoja();
            setLoadingFormulario(false);
        }
    };

    const aoListar = () => setListar(false);

    const limparTela = () => {
        setItem(inicial);
        setRelatorio(relatorioInicial);
        setRelatorioVendaPonto(relatorioVendaPontoInicial);
        setExibirTela(false);
        setExibirGeracaoRelatorio(false);
        setExibirGeracaoRelatorioVendaPonto(false);
        setExibirRelatorioVenda(false);
        setExibirRelatorioVendaPonto(false);
        setRelatorioVendaParamentros(relatorioVendaParamentrosInicial);
        setRelatorioVendaPontoParamentros(relatorioVendaPontoParamentrosInicial);
        setExibirCancelmento(false);
        setCancelamento(cancelamentoInicial);
        setDesabilitarFormas(true);
        setListar(true);
    };

    const excluir = async (id) => {
        await new api().requisitar('DELETE', `${endpointAPI}/${id}`).then((resposta) => {
            aviso.current.show({severity: 'success', summary: 'Confirmação', detail: resposta.mensagem, life: 3000});
            limparTela();
        }).catch((erro) => {
            limparTela();
            debug(erro);
            aviso.current.show({severity: 'error', summary: 'Erro', detail: (erro.response && erro.response.data && erro.response.data.error_description) ? erro.response.data.error_description : 'Ocorreu um erro não tratado', life: 3000});
        });
    };

    const cancelar = async (id) => {
        if (!cancelamento.motivocancelamento) {
            throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe o motivo do cancelamento!'};
        }
        await new api().requisitar('PUT', `${endpointAPI}/cancelar/${item.id}`, cancelamento).then((resposta) => {
            aviso.current.show({severity: 'success', summary: 'Confirmação', detail: resposta.mensagem, life: 3000});
            limparTela();
        }).catch((erro) => {
            limparTela();
            debug(erro);
            aviso.current.show({severity: 'error', summary: 'Erro', detail: (erro.response && erro.response.data && erro.response.data.error_description) ? erro.response.data.error_description : 'Ocorreu um erro não tratado', life: 3000});
        });
    };

    const aoAbirGeracaoRelatorio = () => {
        setExibirGeracaoRelatorio(true);
    };

    const aoAbirGeracaoRelatorioPontoVenda = () => {
        setExibirGeracaoRelatorioVendaPonto(true);
    };

    const aoExcluirItem = (id) => {
        confirmDialog({
            message    : `Confirmar exclusão da ${classe}?`,
            header     : 'Atenção',
            icon       : 'pi pi-exclamation-triangle',
            acceptLabel: 'Sim',
            rejectLabel: 'Não',
            accept     : () => {
                excluir(id);
            },
        });
    };

    const aoCancelarItem = (id) => {
        const _cancelamento = {...cancelamento};
        _cancelamento.id = id;
        setCancelamento(_cancelamento);
        setExibirCancelmento(true);
    };

    const aoSalvar = async () => {
        try {
            if (!item.idloja) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe a loja!'};
            }
            if (!item.idformapagamento) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe a forma de pagamento!'};
            }
            if (!item.qtdpecas) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe a quantidade de peças!'};
            }
            if (!item.valor) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe o valor pago!'};
            }
            if (!item.data) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe a data da compra!'};
            }

            await new api().requisitar((item.id > 0) ? 'put' : 'post', (item.id > 0 ? `${endpointAPI}/${item.id ?? ''}` : endpointAPI), item).then((resposta) => {
                aviso.current.show({severity: 'success', summary: 'Confirmação', detail: resposta.mensagem, life: 3000});
                limparTela();
            }).catch((erro) => {
                limparTela();
                throw  {tipo: 'error', titulo: 'Falha!!', mensagem: erro.response.data ?? 'Ocorreu um erro não tratado'};
            });

        } catch (erro) {
            aviso.current.show({severity: erro.tipo ?? 'error', summary: erro.titulo ?? 'Erro', detail: erro.mensagem ?? erro, life: 3000});
        }
    };

    const aoDigitar = (e, propriedade, elemento) => {
        if(elemento === 'item') {
            const texto = (e.target && e.target.value) || e.value || '';
            let _item = {...item};
            _item[`${propriedade}`] = texto;
            if(propriedade === 'idloja'){
                _item['idformapagamento'] = null;
                carregarFormaPagamento(texto);
            }
            setItem(_item);
        } else if(elemento === 'cancelamento') {
            const texto = (e.target && e.target.value) || e.value || '';
            let _cancelmanto = {...cancelamento};
            _cancelmanto[`${propriedade}`] = texto;
            setCancelamento(_cancelmanto);
        } else if(elemento === 'relatorio') {
            const texto = (e.target && e.target.value) || e.value || '';
            let _relatorio = {...relatorio};
            _relatorio[`${propriedade}`] = texto;
            setRelatorio(_relatorio);
        } else if (elemento === 'relatorio_venda_ponto') {
            const texto = (e.target && e.target.value) || e.value || '';
            let _relatorioVendaPonto = {...relatorioVendaPonto};
            _relatorioVendaPonto[`${propriedade}`] = texto;
            setRelatorioVendaPonto(_relatorioVendaPonto);
        }
    };

    const estruturarRelatorio = (dados, totais) => {
        const vendasFormatada = [];
        dados.forEach((venda) => {
            const vendaFormatada = {
                data: venda.data,
                loja_nome: venda.loja_nome,
                forma_nome: venda.forma_nome,
                qtdpecas: venda.qtdpecas,
                valor: (venda.valor).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
            };
            vendasFormatada.push(vendaFormatada);
        });

        const parametrosRelatorio=  {
            "colunas": [
                { "field": "data", "header": "Data"},
                { "field": "loja_nome", "header": "Loja"},
                { "field": "forma_nome", "header": "Forma de Pagamento"},
                { "field": "qtdpecas", "header": "Quantidade de Peças"},
                { "field": "valor", "header": "Valor"},
            ],
            "dados": vendasFormatada,
            "totais": {
                "qtdPecas": totais.qtdPecas,
                "valor": (totais.valor).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}),
            }
        }
        setRelatorioVendaParamentros(parametrosRelatorio);
    }

    const estruturarRelatorioVendaPonto = (dados) => {
        const vendasPontosFormatados = [];

        for (var data in dados) {
            if (!dados.hasOwnProperty(data)) continue;

            const lojas = dados[data];
            for (var idloja in lojas) {
                if (!lojas.hasOwnProperty(idloja)) continue;

                const dado = lojas[idloja];
                let [ano, mes, dia] = data.split('-')
                const horarioVenda = dado.data_venda ? dado.data_venda.split(' ')[1] : '';
                const horarioSaida = dado.data_ponto ? dado.data_ponto.split(' ')[1] : '';
                const vendaPonto = {
                    data: `${dia}/${mes}/${ano}`,
                    loja_nome: dado.loja_nome,
                    colaborador_nome: dado.colaborador_nome,
                    data_ponto: horarioSaida,
                    data_venda: horarioVenda
                };
                vendasPontosFormatados.push(vendaPonto);
            }
        }

        const parametrosRelatorioVendaPonto=  {
            "colunas": [
                { "field": "data", "header": "Data"},
                { "field": "loja_nome", "header": "Loja"},
                { "field": "colaborador_nome", "header": "Colaborador"},
                { "field": "data_ponto", "header": "Horário de Saída"},
                { "field": "data_venda", "header": "Horário da Última Venda"}

            ],
            "dados": vendasPontosFormatados
        }
        setRelatorioVendaPontoParamentros(parametrosRelatorioVendaPonto);
    }

    const aoGerarRelatorio = async () => {
        try {
            if ((relatorio.periodo_final && relatorio.periodo_inicial) && (relatorio.periodo_inicial > relatorio.periodo_final)) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe um período válido!'};
            }

            await new api().requisitar('post', `${endpointRelatorioAPI}/gerar-relatorio/venda`, relatorio)
                .then((resposta) => {
                    // Exibir mensagem, caso tenha vendas gera relatório
                    setExibirGeracaoRelatorio(false);
                    if(resposta.dados && resposta.dados.length > 0){
                        setExibirRelatorioVenda(true);
                        estruturarRelatorio(resposta.dados, resposta.totais);
                        aviso.current.show({ severity: 'success', summary: 'Confirmação', detail: resposta.mensagem, life: 3000 });
                    } else {
                        limparTela();
                        aviso.current.show({severity: 'warn', summary: 'Nenhuma Venda Encontrada', detail: 'Nenhuma venda encontrada para os filtros aplicados', life: 4000});
                    }
                })
                .catch((erro) => {
                    // Lidar com erros
                    throw { tipo: 'error', titulo: 'Falha!!', mensagem: erro.response.data ?? 'Ocorreu um erro não tratado' };
                });

        } catch (erro) {
            aviso.current.show({severity: erro.tipo ?? 'error', summary: erro.titulo ?? 'Erro', detail: erro.mensagem ?? erro, life: 3000});
        }
    }

    const aoGerarRelatorioVendaPonto = async () => {
        try {
            if ((relatorioVendaPonto.periodo_final && relatorioVendaPonto.periodo_inicial) && (relatorioVendaPonto.periodo_inicial > relatorioVendaPonto.periodo_final)) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe um período válido!'};
            }

            await new api().requisitar('post', `${endpointRelatorioAPI}/gerar-relatorio/ponto-ultima-venda`, relatorioVendaPonto)
                .then((resposta) => {
                    // Exibir mensagem, caso tenha vendas gera relatório
                    setExibirGeracaoRelatorioVendaPonto(false);
                    if(resposta.dados && (Array.isArray(resposta.dados) && resposta.dados.length > 0) || !Array.isArray(resposta.dados)){
                        setExibirRelatorioVendaPonto(true);
                        estruturarRelatorioVendaPonto(resposta.dados);
                        aviso.current.show({ severity: 'success', summary: 'Confirmação', detail: resposta.mensagem, life: 3000 });
                    } else {
                        limparTela();
                        aviso.current.show({severity: 'warn', summary: 'Nenhum Dado Encontrado', detail: 'Nenhum dado encontrada para os filtros aplicados', life: 4000});
                    }
                })
                .catch((erro) => {
                    // Lidar com erros
                    throw { tipo: 'error', titulo: 'Falha!!', mensagem: erro.response.data ?? 'Ocorreu um erro não tratado' };
                });

        } catch (erro) {
            aviso.current.show({severity: erro.tipo ?? 'error', summary: erro.titulo ?? 'Erro', detail: erro.mensagem ?? erro, life: 3000});
        }
    }

    const exportarPdf = (tipo) => {
        import('jspdf').then((jsPDF) => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default('l', 'mm', 'a4');

                // Adiciona o título
                doc.setFontSize(18);
                const titulo = tipo === 'venda' ? 'Vendas' : 'Ponto X Última Venda';
                doc.text(`Relatório de ${titulo}`, 14,20);

                // Define header e body
                const colunas = tipo === 'venda' ?
                    relatorioVendaParamentros.colunas.map((col) => ({ title: col.header, dataKey: col.field }))
                    : relatorioVendaPontoParamentros.colunas.map((col) => ({ title: col.header, dataKey: col.field }));
                const data = tipo === 'venda' ? relatorioVendaParamentros.dados : relatorioVendaPontoParamentros.dados;

                const footerRow = tipo === 'venda' ?  [
                    {
                        "data": "",
                        "loja_nome": "",
                        "forma_nome": "Totais: ",
                        "qtdpecas": relatorioVendaParamentros.totais.qtdPecas,
                        "valor": relatorioVendaParamentros.totais.valor
                    }
                ] : [];

                const body = [...data, ...footerRow];

                doc.autoTable(colunas, body, {
                    margin: {top: 25},
                });

                const nomeRelatorio = tipo === 'venda' ? `Relatório de Vendas.pdf` : `Relatório Ponto X Última Venda.pdf`;
                doc.save(nomeRelatorio);
            });
        });
    };

    const botoesAcaoTela = () => {
        return (<React.Fragment>
            <Button label="Voltar" icon="pi pi-times" className="p-button-text" onClick={(e) => setExibirTela(false)}/>
            {(item.id > 0 && item.status > 0 && permissoesTela.desabilitar && !item.datacancelamento) && <Button label="Cancelar Venda" icon="pi pi-times" className="p-button-text" onClick={(e) => aoCancelarItem(item.id)}/>}
            {item.id > 0 && item.status > 0 && permissoesTela.exluir && <Button label="Excluir" icon="pi pi-times" className="p-button-text" onClick={(e) => aoExcluirItem(item.id)}/>}
            {((item.id > 0 && permissoesTela.alterar && !item.datacancelamento) || (item.id === 0 && permissoesTela.cadastrar)) && <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={aoSalvar}/>}
        </React.Fragment>);
    };

    const botoesAcaoCancelamento = () => {
        return (<React.Fragment>
            <Button label="Voltar" icon="pi pi-times" className="p-button-text" onClick={limparTela}/>
            <Button label="Confirmar" icon="pi pi-check" className="p-button-text" onClick={cancelar}/>
        </React.Fragment>);
    }

    const botoesAcaoTelaRelatorio = () => {
        return (<React.Fragment>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={limparTela}/>
             <Button label="Gerar" icon="pi pi-check" className="p-button-text" onClick={aoGerarRelatorio}/>
        </React.Fragment>);
    };

    const botoesAcaoTelaRelatorioVendaPonto = () => {
        return (<React.Fragment>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={limparTela}/>
            <Button label="Gerar" icon="pi pi-check" className="p-button-text" onClick={aoGerarRelatorioVendaPonto}/>
        </React.Fragment>);
    }

    const botoesRelatorio = (
        <div className="flex align-items-center justify-content-end gap-2">
            <Button label="Salvar em PDF" type="button" icon="pi pi-file-pdf" severity="warning" rounded onClick={() => exportarPdf('venda')} data-pr-tooltip="PDF" />
        </div>
    );

    const botoesRelatorioVendaPonto = (
        <div className="flex align-items-center justify-content-end gap-2">
            <Button label="Salvar em PDF" type="button" icon="pi pi-file-pdf" severity="warning" rounded onClick={() => exportarPdf('venda-ponto')} data-pr-tooltip="PDF" />
        </div>
    );

    const totais = (
        <ColumnGroup>
            <Row>
                <Column footer="Totais:" colSpan={3} footerStyle={{ textAlign: 'right' }} />
                <Column footer={relatorioVendaParamentros.totais.qtdPecas} />
                <Column footer={relatorioVendaParamentros.totais.valor} />
            </Row>
        </ColumnGroup>
    );

    return (
        <Tabela parametros={parametrosTela} listar={listar}>
            <Toast ref={aviso}></Toast>
            <Dialog className='fluid' header={tituloTela} style={{maxWidth: '90%', width: '800px'}} footer={botoesAcaoTela} modal={true} visible={exibeTela} onHide={(e) => setExibirTela(false)}>
                {loadingFormulario ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '250px' }}>
                        <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                    </div>
                ) : (
                    <>
                        <div className="grid formgrid">
                            <div className="flex col-12 p-0 mt-3">
                                <div className="field col-6">
                                    <label htmlFor="idpais">Loja<Obrigatorio>*</Obrigatorio> </label><br/>
                                    <Dropdown className="w-full" autoComplete="none" id="idloja" value={item.idloja} options={lojaLista} onChange={(e) => aoDigitar(e, 'idloja', 'item')} optionLabel="valor"
                                              optionValue="id" required/>
                                </div>
                                <div className="field col-6">
                                    <label htmlFor="idpais">Forma de Pagamento<Obrigatorio>*</Obrigatorio> </label><br/>
                                    <Dropdown className="w-full" autoComplete="none" id="idformapagamento" value={item.idformapagamento} options={formasPagamentoLista} onChange={(e) => aoDigitar(e, 'idformapagamento', 'item')} optionLabel="valor"
                                              optionValue="id" required disabled={desabilitarFormas}/>
                                </div>
                            </div>
                        </div>
                        <div className="grid formgrid">
                            <div className="flex col-12 p-0 mt-3">
                                <div className="field col-6">
                                    <label htmlFor="idpais">Data<Obrigatorio>*</Obrigatorio> </label><br/>
                                    <Calendar
                                        id="time24"
                                        value={item.data}
                                        onChange={(e) => aoDigitar(e, 'data', 'item')}
                                        className="w-full"
                                        dateFormat="dd/mm/yy"
                                        hourFormat="24"
                                        showTime
                                    />
                                </div>
                                <div className="field col-3">
                                    <label htmlFor="valor">Quantidade de Peças<Obrigatorio>*</Obrigatorio></label><br />
                                    <InputNumber
                                        inputId="qtdpecas"
                                        value={item.qtdpecas}
                                        onChange={(e) => aoDigitar(e, 'qtdpecas', 'item')}
                                    />
                                </div>
                                <div className="field col-3">
                                    <label htmlFor="valor">Valor<Obrigatorio>*</Obrigatorio></label><br />
                                    <InputNumber
                                        inputId="valor"
                                        value={item.valor}
                                        onChange={(e) => aoDigitar(e, 'valor', 'item')}
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                    />
                                </div>
                            </div>
                        </div>
                        {
                            item.datacancelamento ?
                                <div className="grid formgrid">
                                    <div className="field col-12 mt-3">
                                        <label htmlFor="descricao">Descrição do Cancelamento</label><br/>
                                        <InputText className="w-full" autoComplete="none" id="descricao" maxLength={200} value={item.motivocancelamento} onChange={(e) => aoDigitar(e, 'motivocancelamento', 'item')} required disabled={true}/>
                                    </div>
                                </div>
                            : null
                        }
                    </>
                )}
            </Dialog>

            <Dialog className='fluid' header='Cancelamento de Venda' style={{maxWidth: '90%', width: '800px'}} footer={botoesAcaoCancelamento} modal={true} visible={exibirCancelamento} onHide={limparTela}>
                <div className="grid formgrid">
                    <div className="field col-12 mt-3">
                        <label htmlFor="descricao">Descrição do Cancelamento</label><br/>
                        <InputText className="w-full" autoComplete="none" id="descricao" maxLength={200} value={cancelamento.motivocancelamento} onChange={(e) => aoDigitar(e, 'motivocancelamento', 'cancelamento')} required/>
                    </div>
                </div>
            </Dialog>

            <Dialog className='fluid' header='Geração de Relatório' style={{maxWidth: '90%', width: '800px'}} footer={botoesAcaoTelaRelatorio} modal={true} visible={exibirGeracaoRelatorio} onHide={limparTela}>
                <div className="grid formgrid">
                    <div className="field col-12">
                        <label htmlFor="idloja">Loja</label><br/>
                        <Dropdown className="w-full" autoComplete="none" id="idloja" value={relatorio.idloja} options={lojaLista} onChange={(e) => aoDigitar(e, 'idloja', 'relatorio')} optionLabel="valor"
                                  optionValue="id" required/>
                    </div>
                </div>
                <div className="grid formgrid">
                    <div className="field col-12">
                        <label htmlFor="idloja">Formas de Pagamento</label><br/>
                        <Dropdown className="w-full" autoComplete="none" id="idloja" value={relatorio.idformapagamento} options={formasPagamentoGeralLista} onChange={(e) => aoDigitar(e, 'idformapagamento', 'relatorio')} optionLabel="valor"
                                  optionValue="id" required />
                    </div>
                </div>
                <div className="grid formgrid">
                    <div className="field col-12">
                        <label htmlFor="idpais">Período Inicial</label><br/>
                        <Calendar
                            value={relatorio.periodo_inicial}
                            onChange={(e) => aoDigitar(e, 'periodo_inicial', 'relatorio')}
                            dateFormat="dd/mm/yy"
                        />
                    </div>
                </div>
                <div className="grid formgrid">
                    <div className="field col-12">
                        <label htmlFor="idpais">Período Final</label><br/>
                        <Calendar
                            value={relatorio.periodo_final}
                            onChange={(e) => aoDigitar(e, 'periodo_final', 'relatorio')}
                            dateFormat="dd/mm/yy"
                        />
                    </div>
                </div>
            </Dialog>

            <Dialog className='fluid' header='Geração de Relatório de Ponto X Última Venda' style={{maxWidth: '90%', width: '800px'}} footer={botoesAcaoTelaRelatorioVendaPonto} modal={true} visible={exibirGeracaoRelatorioVendaPonto} onHide={limparTela}>
                <div className="grid formgrid">
                    <div className="field col-12">
                        <label htmlFor="idloja">Loja</label><br/>
                        <Dropdown className="w-full" autoComplete="none" id="idloja" value={relatorioVendaPonto.idloja} options={lojaLista} onChange={(e) => aoDigitar(e, 'idloja', 'relatorio_venda_ponto')} optionLabel="valor"
                                  optionValue="id" required/>
                    </div>
                </div>
                <div className="grid formgrid">
                    <div className="field col-12">
                        <label htmlFor="idpais">Período Inicial</label><br/>
                        <Calendar
                            value={relatorioVendaPonto.periodo_inicial}
                            onChange={(e) => aoDigitar(e, 'periodo_inicial', 'relatorio_venda_ponto')}
                            dateFormat="dd/mm/yy"
                        />
                    </div>
                </div>
                <div className="grid formgrid">
                    <div className="field col-12">
                        <label htmlFor="idpais">Período Final</label><br/>
                        <Calendar
                            value={relatorioVendaPonto.periodo_final}
                            onChange={(e) => aoDigitar(e, 'periodo_final', 'relatorio_venda_ponto')}
                            dateFormat="dd/mm/yy"
                        />
                    </div>
                </div>
            </Dialog>

            <Dialog
                className='fluid'
                header={`Relatório de Vendas`}
                style={{ maxWidth: '95%', width: '95%' }}
                contentStyle={{ height: '100%', width: '100%' }}
                modal={true}
                visible={exibirRelatorioVenda}
                onHide={limparTela}
                closeOnEscape={false}
                maximizable
            >
                <Tooltip target=".export-buttons>button" position="bottom" />
                <DataTable value={relatorioVendaParamentros.dados} header={botoesRelatorio} tableStyle={{ width: '100%' }} footerColumnGroup={totais} showGridlines scrollable scrollHeight="flex">
                    {relatorioVendaParamentros.colunas.map((col, index) => {
                        return (
                            <Column
                                key={index}
                                field={col.field}
                                header={col.header}
                            />
                        );
                    })}
                </DataTable>
            </Dialog>

            <Dialog
                className='fluid'
                header={`Relatório de Ponto X Última Venda`}
                style={{ maxWidth: '95%', width: '95%' }}
                contentStyle={{ height: '100%', width: '100%' }}
                modal={true}
                visible={exibirRelatorioVendaPonto}
                onHide={limparTela}
                closeOnEscape={false}
                maximizable
            >
                <Tooltip target=".export-buttons>button" position="bottom" />
                <DataTable value={relatorioVendaPontoParamentros.dados} header={botoesRelatorioVendaPonto} tableStyle={{ width: '100%' }} showGridlines scrollable scrollHeight="flex">
                    {relatorioVendaPontoParamentros.colunas.map((col, index) => {
                        return (
                            <Column
                                key={index}
                                field={col.field}
                                header={col.header}
                            />
                        );
                    })}
                </DataTable>
            </Dialog>
        </Tabela>
    );
}

export default Venda;
