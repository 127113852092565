import React, {useEffect, useRef, useState} from "react";
import {Toast}                              from "primereact/toast";
import {Button}                             from "primereact/button";
import {confirmDialog}                      from "primereact/confirmdialog";
import {Dialog}                             from "primereact/dialog";
import { ProgressSpinner }                  from 'primereact/progressspinner';
import {InputNumber}                        from "primereact/inputnumber";
import {Calendar}                           from  "primereact/calendar";
import {Dropdown}                           from "primereact/dropdown";

import api                                  from "../../services/api";
import {Obrigatorio, Container}             from "../../styles/global";
import Tabela                               from "../../components/tabela";
import {parametrosPadrao}                   from "../../configs/constantes";
import debug                                from "../../utils/debug";
import {Toolbar}                            from "primereact/toolbar";
import {Tooltip}                            from "primereact/tooltip";
import {DataTable}                          from "primereact/datatable";
import {Column}                             from "primereact/column";
import {MultiSelect}                        from "primereact/multiselect";

const Caixa = () => {
    const classe = 'Caixa';
    const tabela = 'caixa';
    const endpointAPI = 'vendas/caixa';
    const endpointRelatorioAPI = 'relatorios/relatorio';
    const aviso = useRef(null);
    const inicial = {id: 0, idloja: null, dataabertura: null, datafechamento: null, valorabertura: null, valorfechamento: null};
    const [item, setItem] = useState(inicial);

    const [tituloTela, setTituloTela] = useState('');
    const [exibeTela, setExibirTela] = useState(false);
    const [listar, setListar] = useState(false);
    const [permissoesTela, setPermissoesTela] = useState(null);
    const [parametrosTela, setParametrosTela] = useState(parametrosPadrao);
    const [lojaLista, setLojaLista] = useState([]);
    const [formasPagamentoLista, setFormasPagamentoLista] = useState([]);
    const [loadingFormulario, setLoadingFormulario] = useState(true);

    const inicialCaixaAtual = {id: 0, idloja: null, dataabertura: null, datafechamento: null, valorabertura: null, valorfechamento: null};
    const [caixaAtual, setCaixaAtual] = useState(false);
    const [usuarioLoja, setUsuarioLoja] = useState(false);

    const [exibirControleCaixa, setExibirControleCaixa] = useState(false);

    const relatorioCaixaInicial = {idformaspagamento: null, idloja: null, periodo_inicial: null, periodo_final: null}
    const [relatorioCaixa, setRelatorioCaixa] = useState(relatorioCaixaInicial);
    const [exibirGeracaoRelatorioCaixa, setExibirGeracaoRelatorioCaixa] = useState(false);
    const [exibirRelatorioCaixa, setExibirRelatorioCaixa] = useState(false);
    const relatorioCaixaParamentrosInicial = {colunas: [], dados: [], loja: {}, totais: {}};
    const [relatorioCaixaParamentros, setRelatorioCaixaParamentros] = useState(relatorioCaixaParamentrosInicial);

    const relatorioFluxoCaixaInicial = {idloja: null, periodo_inicial: null, periodo_final: null}
    const [relatorioFluxoCaixa, setRelatorioFluxoCaixa] = useState(relatorioFluxoCaixaInicial);
    const [exibirGeracaoRelatorioFluxoCaixa, setExibirGeracaoRelatorioFluxoCaixa] = useState(false);
    const [exibirRelatorioFluxoCaixa, setExibirRelatorioFluxoCaixa] = useState(false);
    const relatorioFluxoCaixaParamentrosInicial = {colunas: [], dados: [], loja: {}, totais: {}};
    const [relatorioFluxoCaixaParamentros, setRelatorioFluxoCaixaParamentros] = useState(relatorioFluxoCaixaParamentrosInicial);

    useEffect((e) => {
        new api().requisitar('get', `configuracao/tela/caixa/permissoes`).then((resposta) => {
            setPermissoesTela(resposta);
            const parametros = {
                pesquisarTabela: true,
                artigo: 'a',
                nomeClasse: classe,
                tabela: tabela,
                caminhoBreadcrumb: [{ label: 'Vendas' }, { label: 'Controle de Caixa' }],
                endpointApi: endpointAPI,
                excluirRegistro: true,
                tabelaDados      : {
                    reordenar: false,
                    multiplaSelecao: true,
                    apresentarDados: resposta.listar ?? false,
                    botaoAdicionar: resposta.cadastrar ?? false,
                    botaoExcluir: resposta.excluir ?? false,
                    botaoEditarRegistro: resposta.alterar ?? false,
                    botaoExcluirRegistro: resposta.excluir ?? false,
                    botaoAuditoriaRegistro: resposta.auditoria ?? false,
                    botaoVisualizarRegistro: resposta.visualizar ?? false,
                    botaoCustomizavel      : resposta.relatorio ?
                        <>
                            <Button label="Gerar Relatório de Caixas" icon="pi pi-plus" className="p-button-info mt-2 mr-2" onClick={() => aoAbirGeracaoRelatorioCaixa()}/>
                            <Button label="Gerar Relatório de Fluxo de Caixa" icon="pi pi-plus" className="p-button-info mt-2 mr-2" onClick={() => aoAbirGeracaoRelatorioFluxoCaixa()}/>
                        </>
                        : null,
                    filtroStatus: false,
                    colunas: [
                        { legenda: 'Loja', campo: 'lojanome', ordenavel: true },
                        { legenda: 'Data Abertura', campo: 'dataabertura', ordenavel: true },
                        { legenda: 'Data Fechamento', campo: 'datafechamento', ordenavel: true },
                        { legenda: 'Valor na Abertura de Caixa', campo: 'valorabertura', ordenavel: true },
                        { legenda: 'Valor no Fechamento de Caixa', campo: 'valorfechamento', ordenavel: true }
                    ]
                },
                abrirFormulario  : aoAbrirFormulario,
                finalizarPesquisa: aoListar
            }
            setParametrosTela(parametros);
        }).catch((erro) => {
            throw  {tipo: 'error', titulo: 'Falha!!', mensagem: erro.response.data ?? 'Ocorreu um erro não tratado'};
        });

        new api().requisitar('get', 'cadastro/loja/combo').then((resposta) => {
            setLojaLista(resposta);
        }).catch((erro) => {
            debug(erro);
        });

        new api().requisitar('get', 'vendas/caixa/verificar-caixa').then((resposta) => {
            if(resposta.caixa) {
                setCaixaAtual(resposta.caixa);
            }
        }).catch((erro) => {
            debug(erro);
        });

        new api().requisitar('get', 'cadastro/loja/usuario-loja').then((resposta) => {
            if(resposta['id'] !== false) {
                setUsuarioLoja(resposta.id);
            }
        }).catch((erro) => {
            debug(erro);
        });

        new api().requisitar('get', 'vendas/formas-pagamento/combo').then((resposta) => {
            setFormasPagamentoLista(resposta);
        }).catch((erro) => {
            debug(erro);
        });

    }, []);

    const aoAbirGeracaoRelatorioCaixa = () => {
        setExibirGeracaoRelatorioCaixa(true);
    }

    const aoAbirGeracaoRelatorioFluxoCaixa = () => {
        setExibirGeracaoRelatorioFluxoCaixa(true);
    }

    const formatarData = (data) => {
        const [parteData, parteHora] = data.split(' ');
        const [ano, mes, dia] = parteData.split('-');
        const [horas, minutos, segundos] = parteHora.split(':');
        return new Date(ano, mes - 1, dia, horas, minutos, segundos);
    };

    const alterarCaixa = () => {
        setExibirControleCaixa(true);
    }

    const verificarCaixaAtual = () => {
        new api().requisitar('get', 'vendas/caixa/verificar-caixa').then((resposta) => {
            if(resposta.caixa) {
                resposta.caixa.dataabertura = resposta.caixa.dataabertura ? formatarData(resposta.caixa.dataabertura) : null;
                setCaixaAtual(resposta.caixa);
            } else {
                setCaixaAtual(inicialCaixaAtual);
            }
        }).catch((erro) => {
            setCaixaAtual(inicialCaixaAtual);
            debug(erro);
        });
    };

    const verificaUsuarioLoja = async () => {
        try {
            const loja = await new api().requisitar('get', 'cadastro/loja/usuario-loja');
            let _item = item;
            if (loja['id'] !== false) {
                setLojaLista([loja]);
                _item.idloja = loja['id'];
                setItem(_item);
            }
        } catch (erro) {
            debug(erro);
        }
    }

    const aoAbrirFormulario = async (registro) => {
        setItem(inicial);
        setExibirTela(true);
        setLoadingFormulario(true);
        if (registro.id) {
            new api().requisitar('get', `${endpointAPI}/${registro.id}`).then(async (resposta) => {
                resposta.dataabertura = formatarData(resposta.dataabertura);
                resposta.datafechamento = !resposta.datafechamento ? resposta.datafechamento : formatarData(resposta.datafechamento);
                setItem(resposta);
                setLoadingFormulario(false);
            }).catch((erro) => {
                debug(erro);
            })
            setTituloTela(`Alterar ${classe}`);
        } else {
            setTituloTela(`Cadastro de ${classe}`);
            await verificaUsuarioLoja();
            setLoadingFormulario(false);
        }
    };

    const aoListar = () => setListar(false);

    const limparTela = () => {
        setItem(inicial);
        setExibirTela(false);

        verificarCaixaAtual();
        setExibirControleCaixa(false);
        setRelatorioCaixa(relatorioCaixaInicial);
        setRelatorioCaixaParamentros(relatorioCaixaParamentrosInicial);
        setExibirRelatorioCaixa(false);
        setExibirGeracaoRelatorioCaixa(false);

        setRelatorioFluxoCaixa(relatorioFluxoCaixaInicial);
        setRelatorioFluxoCaixaParamentros(relatorioFluxoCaixaParamentrosInicial);
        setExibirRelatorioFluxoCaixa(false);
        setExibirGeracaoRelatorioFluxoCaixa(false);
        setListar(true);
    };

    const estruturarRelatorioCaixa = (dados, loja) => {
        const caixasFormatado = [];

        for (var data in dados) {
            if (!dados.hasOwnProperty(data)) continue;

            const dado = dados[data];
            let [ano, mes, dia] = data.split('-')
            const caixa = {
                data: `${dia}/${mes}/${ano}`,
                valorabertura: (dado.valorabertura).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}),
                valorvendas: (dado.valorvendas).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}),
                valorfechamento: (dado.valorfechamento).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}),
                valorfechamentosistema: (dado.valorfechamentosistema).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}),
                balanco: (dado.valorfechamento - dado.valorfechamentosistema).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
            };
            caixasFormatado.push(caixa);
        }

        const parametrosRelatorioCaixa=  {
            "colunas": [
                { "field": "data", "header": "Data"},
                { "field": "valorabertura", "header": "Valor Abertura"},
                { "field": "valorvendas", "header": "Valor Vendido (Entrada direta em caixa)"},
                { "field": "valorfechamento", "header": "Valor Fechamento"},
                { "field": "valorfechamentosistema", "header": "Valor Fechamento Sistema"},
                { "field": "balanco", "header": "Balanço"}
            ],
            "dados": caixasFormatado,
            "loja": loja
        }
        setRelatorioCaixaParamentros(parametrosRelatorioCaixa);
    }

    const estruturarRelatorioFluxoCaixa = (dados, loja, formasPagamento) => {
        const fluxoCaixasFormatado = [];
        for (var data in dados) {
            if (!dados.hasOwnProperty(data)) continue;

            const dado = dados[data];
            let [ano, mes, dia] = data.split('-')
            const vendasNaData = {
                data: `${dia}/${mes}/${ano}`,
                entradas: dado.entradas ? dado.entradas.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : null,
                retiradas: dado.retiradas ? dado.retiradas.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : null,
                total: dado.total.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
            };

            const formasVendas = dado['vendas'] ?? {};
            for (var forma in formasVendas) {
                if (!formasVendas.hasOwnProperty(forma)) continue;

                vendasNaData[forma] = formasVendas[forma].toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
            }

            fluxoCaixasFormatado.push(vendasNaData);
        }

        const colunas = [
            { "field": "data", "header": "Data" },
        ];
        for (var forma in formasPagamento) {
            if (!formasPagamento.hasOwnProperty(forma)) continue;

            colunas.push({ "field": forma, "header": formasPagamento[forma] });
        }
        colunas.push({ "field": "entradas", "header": "Entradas" });
        colunas.push({ "field": "retiradas", "header": "Retiradas" });
        colunas.push({ "field": "total", "header": "Total" });

        const parametrosRelatorioFluxoCaixa=  {
            "colunas": colunas,
            "dados": fluxoCaixasFormatado,
            "loja": loja
        }
        setRelatorioFluxoCaixaParamentros(parametrosRelatorioFluxoCaixa);
    }

    const excluir = async (id) => {
        await new api().requisitar('DELETE', `${endpointAPI}/${id}`).then((resposta) => {
            aviso.current.show({severity: 'success', summary: 'Confirmação', detail: resposta.mensagem, life: 3000});
            limparTela();
        }).catch((erro) => {
            limparTela();
            debug(erro);
            aviso.current.show({severity: 'error', summary: 'Erro', detail: (erro.response && erro.response.data && erro.response.data.error_description) ? erro.response.data.error_description : 'Ocorreu um erro não tratado', life: 3000});
        });
    };

    const aoExcluirItem = (id) => {
        confirmDialog({
            message    : `Confirmar exclusão do ${classe}?`,
            header     : 'Atenção',
            icon       : 'pi pi-exclamation-triangle',
            acceptLabel: 'Sim',
            rejectLabel: 'Não',
            accept     : () => {
                excluir(id);
            },
        });
    };

    const aoSalvar = async () => {
        try {
            if (!item.idloja) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe a loja!'};
            }
            if (!item.dataabertura) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe a data de abertura!'};
            }
            if (!item.valorabertura) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe o valor de caixa na abertura!'};
            }

            await new api().requisitar((item.id > 0) ? 'put' : 'post', (item.id > 0 ? `${endpointAPI}/${item.id ?? ''}` : endpointAPI), item).then((resposta) => {
                aviso.current.show({severity: 'success', summary: 'Confirmação', detail: resposta.mensagem, life: 3000});
                limparTela();
            }).catch((erro) => {
                limparTela();
                throw  {tipo: 'error', titulo: 'Falha!!', mensagem: erro.response.data ?? 'Ocorreu um erro não tratado'};
            });

        } catch (erro) {
            aviso.current.show({severity: erro.tipo ?? 'error', summary: erro.titulo ?? 'Erro', detail: erro.mensagem ?? erro, life: 3000});
        }
    };

    const aoAlterarCaixa = async () => {
        try {

            await new api().requisitar('post', `${endpointAPI}/operar-caixa`, caixaAtual).then((resposta) => {
                aviso.current.show({severity: 'success', summary: 'Confirmação', detail: resposta.mensagem, life: 3000});
                limparTela();
            }).catch((erro) => {
                limparTela();
                throw  {tipo: 'error', titulo: 'Falha!!', mensagem: erro.response.data ?? 'Ocorreu um erro não tratado'};
            });
        } catch (erro) {
            aviso.current.show({severity: erro.tipo ?? 'error', summary: erro.titulo ?? 'Erro', detail: erro.mensagem ?? erro, life: 3000});
        }
    };

    const aoGerarRelatorioCaixa = async () => {
        try {
            if (!relatorioCaixa.idloja) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe a loja!'};
            }
            if ((relatorioCaixa.periodo_final && relatorioCaixa.periodo_inicial) && (relatorioCaixa.periodo_inicial > relatorioCaixa.periodo_final)) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe um período válido!'};
            }

            await new api().requisitar('post', `${endpointRelatorioAPI}/gerar-relatorio/caixa`, relatorioCaixa)
                .then((resposta) => {
                    if(resposta.dados && (Array.isArray(resposta.dados) && resposta.dados.length > 0) || !Array.isArray(resposta.dados)){
                        estruturarRelatorioCaixa(resposta.dados, resposta.loja);
                        setExibirGeracaoRelatorioCaixa(false);
                        setExibirRelatorioCaixa(true);
                        aviso.current.show({ severity: 'success', summary: 'Confirmação', detail: resposta.mensagem, life: 3000 });
                    } else {
                        limparTela();
                        aviso.current.show({severity: 'warn', summary: 'Nenhum Dado Encontrado', detail: 'Nenhum dado encontrada para os filtros aplicados', life: 4000});
                    }
                })
                .catch((erro) => {
                    // Lidar com erros
                    throw { tipo: 'error', titulo: 'Falha!!', mensagem: erro.response.data ?? 'Ocorreu um erro não tratado' };
                });

        } catch (erro) {
            aviso.current.show({severity: erro.tipo ?? 'error', summary: erro.titulo ?? 'Erro', detail: erro.mensagem ?? erro, life: 3000});
        }
    }

    const aoGerarRelatorioFluxoCaixa = async () => {
        try {
            if (!relatorioFluxoCaixa.idloja) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe a loja!'};
            }
            if ((relatorioFluxoCaixa.periodo_final && relatorioFluxoCaixa.periodo_inicial) && (relatorioFluxoCaixa.periodo_inicial > relatorioFluxoCaixa.periodo_final)) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe um período válido!'};
            }

            await new api().requisitar('post', `${endpointRelatorioAPI}/gerar-relatorio/fluxo-caixa`, relatorioFluxoCaixa)
                .then((resposta) => {
                    if(resposta.dados && (Array.isArray(resposta.dados) && resposta.dados.length > 0) || !Array.isArray(resposta.dados)){
                        estruturarRelatorioFluxoCaixa(resposta.dados, resposta.loja, resposta.formaspagamento);
                        setExibirGeracaoRelatorioFluxoCaixa(false);
                        setExibirRelatorioFluxoCaixa(true);
                        aviso.current.show({ severity: 'success', summary: 'Confirmação', detail: resposta.mensagem, life: 3000 });
                    } else {
                        limparTela();
                        aviso.current.show({severity: 'warn', summary: 'Nenhum Dado Encontrado', detail: 'Nenhum dado encontrada para os filtros aplicados', life: 4000});
                    }
                })
                .catch((erro) => {
                    // Lidar com erros
                    throw { tipo: 'error', titulo: 'Falha!!', mensagem: erro.response.data ?? 'Ocorreu um erro não tratado' };
                });

        } catch (erro) {
            aviso.current.show({severity: erro.tipo ?? 'error', summary: erro.titulo ?? 'Erro', detail: erro.mensagem ?? erro, life: 3000});
        }
    }

    const aoDigitar = (e, propriedade, elemento) => {
        const texto = (e.target && e.target.value) || e.value || '';
        if(elemento === 'item'){
            let _item = {...item};
            _item[`${propriedade}`] = texto;
            setItem(_item);
        } else if (elemento === 'caixa-atual') {
            let _caixaAtual = {...caixaAtual};
            _caixaAtual[`${propriedade}`] = texto;
            setCaixaAtual(_caixaAtual);
        } else if (elemento === 'relatorio-caixa') {
            let _relatorioCaixa = {...relatorioCaixa};
            _relatorioCaixa[`${propriedade}`] = texto;
            setRelatorioCaixa(_relatorioCaixa);
        } else if (elemento === 'relatorio-fluxo-caixa') {
            let _relatorioFluxoCaixa = {...relatorioFluxoCaixa};
            _relatorioFluxoCaixa[`${propriedade}`] = texto;
            setRelatorioFluxoCaixa(_relatorioFluxoCaixa);
        }

    };

    const exportarPdf = (tipo) => {
        import('jspdf').then((jsPDF) => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default('l', 'mm', 'a4');

                // Adiciona o título
                doc.setFontSize(18);
                const titulo = tipo === 'caixa' ? `Caixas - ${relatorioCaixaParamentros.loja.nome}` : `Fluxo de Caixa - ${relatorioFluxoCaixaParamentros.loja.nome}`;
                doc.text(`Relatório de ${titulo}`, 14,20);

                // Define header e body
                const colunas = tipo === 'caixa' ?
                    relatorioCaixaParamentros.colunas.map((col) => ({ title: col.header, dataKey: col.field }))
                    : relatorioFluxoCaixaParamentros.colunas.map((col) => ({ title: col.header, dataKey: col.field }));
                const data = tipo === 'caixa' ? relatorioCaixaParamentros.dados : relatorioFluxoCaixaParamentros.dados;

                const footerRow = [];

                const body = [...data, ...footerRow];

                doc.autoTable(colunas, body, {
                    margin: {top: 25},
                });

                const nomeRelatorio = tipo === 'caixa' ? `Relatório de Caixas - ${relatorioCaixaParamentros.loja.nome}.pdf` : `Relatório de Fluxo de Caixa - ${relatorioFluxoCaixaParamentros.loja.nome}.pdf`;
                doc.save(nomeRelatorio);
            });
        });
    };

    const botoesAcaoTela = () => {
        return (<React.Fragment>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={(e) => setExibirTela(false)}/>
            {item.id > 0 && item.status > 0 && permissoesTela.exluir && <Button label="Excluir" icon="pi pi-times" className="p-button-text" onClick={(e) => aoExcluirItem(item.id)}/>}
            {((item.id > 0 && permissoesTela.alterar) || permissoesTela.cadastrar) && <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={aoSalvar}/>}
        </React.Fragment>);
    };

    const botoesAcaoTelaControleCaixa = () => {
        return (<React.Fragment>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={limparTela}/>
            <Button label={caixaAtual.id ? "Fechar Caixa" : "Abrir Caixa"} icon="pi pi-check" className="p-button-text" onClick={aoAlterarCaixa}/>
        </React.Fragment>);
    }

    const botoesControleCaixa = () => {
        return usuarioLoja ? (
            caixaAtual.id > 0  ? <Button label="Fechar Caixa" icon="pi pi-lock" className="p-button-info mt-2 mr-2" onClick={alterarCaixa} disabled={caixaAtual.datafechamento} />
                :  <Button label="Abrir Caixa" icon="pi pi-lock-open" className="p-button-info mt-2 mr-2" onClick={alterarCaixa} />
        ) : null;
    }

    const textosControleCaixa = () => {
        const estadoCaixa = caixaAtual.id > 0 ? (caixaAtual.datafechamento ? 'Caixa Fechado' : 'Caixa Aberto' ) : 'Caixa não foi aberto';
        const corIcone = caixaAtual.id > 0 ? (caixaAtual.datafechamento ? 'pi pi-times-circle' : 'pi pi-check-circle') : 'pi pi-question-circle';

        return usuarioLoja ? (
                <div className='flex flex-row align-items-center justify-content-center gap-3'>
                    <i className={`${corIcone} text-2xl`} style={{ color: corIcone === 'pi pi-check-circle' ? 'green' : (corIcone === 'pi pi-times-circle' ? 'red' : 'gray') }}></i>
                    <h3 className='m-0' style={{ color: corIcone === 'pi pi-check-circle' ? 'green' : (corIcone === 'pi pi-times-circle' ? 'red' : 'gray') }}>{estadoCaixa}</h3>
                </div>
        ) : null;
    }

    const botoesAcaoTelaRelatorioCaixa = () => {
        return (<React.Fragment>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={limparTela}/>
            <Button label="Gerar" icon="pi pi-check" className="p-button-text" onClick={aoGerarRelatorioCaixa}/>
        </React.Fragment>);
    };

    const botoesAcaoTelaRelatorioFluxoCaixa = () => {
        return (<React.Fragment>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={limparTela}/>
            <Button label="Gerar" icon="pi pi-check" className="p-button-text" onClick={aoGerarRelatorioFluxoCaixa}/>
        </React.Fragment>);
    };

    const botoesRelatorioCaixa = (
        <div className="flex align-items-center justify-content-end gap-2">
            <Button label="Salvar em PDF" type="button" icon="pi pi-file-pdf" severity="warning" rounded onClick={() => exportarPdf('caixa')} data-pr-tooltip="PDF" />
        </div>
    );

    const botoesRelatorioFluxoCaixa = (
        <div className="flex align-items-center justify-content-end gap-2">
            <Button label="Salvar em PDF" type="button" icon="pi pi-file-pdf" severity="warning" rounded onClick={() => exportarPdf('fluxo-caixa')} data-pr-tooltip="PDF" />
        </div>
    );

    return (
        <Container>
            { permissoesTela && permissoesTela.gerenciarcaixa ? <Toolbar left={botoesControleCaixa} right={textosControleCaixa} /> : null }
            <Tabela parametros={parametrosTela} listar={listar}>
                <Toast ref={aviso}></Toast>
                <Dialog className='fluid' header={tituloTela} style={{maxWidth: '90%', width: '800px'}} footer={botoesAcaoTela} modal={true} visible={exibeTela} onHide={(e) => setExibirTela(false)}>
                    {loadingFormulario ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '250px' }}>
                            <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                        </div>
                    ) : (
                        <>
                            <div className="grid formgrid">
                                <div className="flex col-12 p-0 mt-3">
                                    <div className="field col-6">
                                        <label htmlFor="idpais">Loja<Obrigatorio>*</Obrigatorio> </label><br/>
                                        <Dropdown className="w-full" autoComplete="none" id="idloja" value={item.idloja} options={lojaLista} onChange={(e) => aoDigitar(e, 'idloja', 'item')} optionLabel="valor"
                                                  optionValue="id" required/>
                                    </div>
                                </div>
                            </div>
                            <div className="grid formgrid">
                                <div className="flex col-12 p-0 mt-3">
                                    <div className="field col-6">
                                        <label htmlFor="idpais">Data Abertura<Obrigatorio>*</Obrigatorio> </label><br/>
                                        <Calendar
                                            id="time24"
                                            value={item.dataabertura}
                                            onChange={(e) => aoDigitar(e, 'dataabertura', 'item')}
                                            className="w-full"
                                            dateFormat="dd/mm/yy"
                                            hourFormat="24"
                                            showTime
                                        />
                                    </div>
                                    <div className="field col-6">
                                        <label htmlFor="valor">Valor Abertura<Obrigatorio>*</Obrigatorio></label><br />
                                        <InputNumber
                                            inputId="valorabertura"
                                            value={item.valorabertura}
                                            onChange={(e) => aoDigitar(e, 'valorabertura', 'item')}
                                            mode="currency"
                                            currency="BRL"
                                            locale="pt-BR"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="grid formgrid">
                                <div className="flex col-12 p-0 mt-3">
                                    <div className="field col-6">
                                        <label htmlFor="idpais">Data Fechamento</label><br/>
                                        <Calendar
                                            id="time24"
                                            value={item.datafechamento}
                                            onChange={(e) => aoDigitar(e, 'datafechamento', 'item')}
                                            className="w-full"
                                            dateFormat="dd/mm/yy"
                                            hourFormat="24"
                                            showTime
                                        />
                                    </div>
                                    <div className="field col-6">
                                        <label htmlFor="valor">Valor Fechamento</label><br />
                                        <InputNumber
                                            inputId="valorfechamento"
                                            value={item.valorfechamento}
                                            onChange={(e) => aoDigitar(e, 'valorfechamento', 'item')}
                                            mode="currency"
                                            currency="BRL"
                                            locale="pt-BR"
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </Dialog>

                <Dialog className='fluid' header={caixaAtual.id > 0 ? 'Fechamento de Caixa' : 'Abertura de Caixa'} style={{maxWidth: '90%', width: '800px'}} footer={botoesAcaoTelaControleCaixa} modal={true} visible={exibirControleCaixa} onHide={limparTela}>
                    <div className="grid formgrid">
                        <div className="flex col-12 p-0 mt-3">
                            <div className="field col-6">
                                <label htmlFor="idpais">Loja<Obrigatorio>*</Obrigatorio> </label><br/>
                                <Dropdown className="w-full" autoComplete="none" id="idloja" value={caixaAtual.id > 0 ? caixaAtual.idloja : usuarioLoja} options={lojaLista} onChange={(e) => aoDigitar(e, 'idloja', 'caixa-atual')} optionLabel="valor"
                                          optionValue="id" required disabled={true}/>
                            </div>
                        </div>
                    </div>
                    <div className="grid formgrid">
                        <div className="flex col-12 p-0 mt-3">
                            <div className="field col-6">
                                <label htmlFor="idpais">Data Abertura<Obrigatorio>*</Obrigatorio> </label><br/>
                                <Calendar
                                    id="time24"
                                    value={caixaAtual.id > 0 ? caixaAtual.dataabertura : new Date()}
                                    onChange={(e) => aoDigitar(e, 'dataabertura', 'caixa-atual')}
                                    className="w-full"
                                    dateFormat="dd/mm/yy"
                                    hourFormat="24"
                                    showTime
                                    disabled={true}
                                />
                            </div>
                            <div className="field col-6">
                                <label htmlFor="valor">Valor Abertura<Obrigatorio>*</Obrigatorio></label><br />
                                <InputNumber
                                    inputId="valorabertura"
                                    value={caixaAtual.valorabertura}
                                    onChange={(e) => aoDigitar(e, 'valorabertura', 'caixa-atual')}
                                    mode="currency"
                                    currency="BRL"
                                    locale="pt-BR"
                                    disabled={caixaAtual.id > 0}
                                />
                            </div>
                        </div>
                    </div>
                    { caixaAtual.id ?
                        <div className="grid formgrid">
                            <div className="flex col-12 p-0 mt-3">
                                <div className="field col-6">
                                    <label htmlFor="idpais">Data Fechamento</label><br/>
                                    <Calendar
                                        id="time24"
                                        value={new Date()}
                                        onChange={(e) => aoDigitar(e, 'datafechamento', 'caixa-atual')}
                                        className="w-full"
                                        dateFormat="dd/mm/yy"
                                        hourFormat="24"
                                        showTime
                                        disabled={true}
                                    />
                                </div>
                                <div className="field col-6">
                                    <label htmlFor="valor">Valor Fechamento</label><br />
                                    <InputNumber
                                        inputId="valorfechamento"
                                        value={caixaAtual.valorfechamento}
                                        onChange={(e) => aoDigitar(e, 'valorfechamento', 'caixa-atual')}
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                    />
                                </div>
                            </div>
                        </div>
                        : null }
                </Dialog>
            </Tabela>

            <Dialog className='fluid' header='Geração de Relatório de Caixas' style={{maxWidth: '90%', width: '800px'}} footer={botoesAcaoTelaRelatorioCaixa} modal={true} visible={exibirGeracaoRelatorioCaixa} onHide={limparTela}>
                <div className="grid formgrid">
                    <div className="field col-12">
                        <label htmlFor="idloja">Loja <Obrigatorio>*</Obrigatorio> </label><br/>
                        <Dropdown className="w-full" autoComplete="none" id="idloja" value={relatorioCaixa.idloja} options={lojaLista} onChange={(e) => aoDigitar(e, 'idloja', 'relatorio-caixa')} optionLabel="valor"
                                  optionValue="id" required/>
                    </div>
                </div>
                <div className="grid formgrid">
                    <div className="field col-12">
                        <label htmlFor="idformaspagamento">Formas de Pagamento (Considerar como entrada direta ao caixa)</label><br/>
                        <MultiSelect
                            className="w-full"
                            autoComplete="none"
                            id="idlojas"
                            value={relatorioCaixa.idformaspagamento}
                            options={formasPagamentoLista}
                            onChange={(e) => aoDigitar(e, 'idformaspagamento', 'relatorio-caixa')}
                            optionLabel="valor"
                            optionValue="id"
                            required
                            placeholder="Selecione uma ou mais formas de pagamento"
                        />
                    </div>
                </div>
                <div className="grid formgrid">
                    <div className="field col-12">
                        <label htmlFor="idpais">Período Inicial</label><br/>
                        <Calendar
                            value={relatorioCaixa.periodo_inicial}
                            onChange={(e) => aoDigitar(e, 'periodo_inicial', 'relatorio-caixa')}
                            dateFormat="dd/mm/yy"
                        />
                    </div>
                </div>
                <div className="grid formgrid">
                    <div className="field col-12">
                        <label htmlFor="idpais">Período Final</label><br/>
                        <Calendar
                            value={relatorioCaixa.periodo_final}
                            onChange={(e) => aoDigitar(e, 'periodo_final', 'relatorio-caixa')}
                            dateFormat="dd/mm/yy"
                        />
                    </div>
                </div>
            </Dialog>

            <Dialog className='fluid' header='Geração de Relatório de Fluxo de Caixa' style={{maxWidth: '90%', width: '800px'}} footer={botoesAcaoTelaRelatorioFluxoCaixa} modal={true} visible={exibirGeracaoRelatorioFluxoCaixa} onHide={limparTela}>
                <div className="grid formgrid">
                    <div className="field col-12">
                        <label htmlFor="idloja">Loja <Obrigatorio>*</Obrigatorio> </label><br/>
                        <Dropdown className="w-full" autoComplete="none" id="idloja" value={relatorioFluxoCaixa.idloja} options={lojaLista} onChange={(e) => aoDigitar(e, 'idloja', 'relatorio-fluxo-caixa')} optionLabel="valor"
                                  optionValue="id" required/>
                    </div>
                </div>
                <div className="grid formgrid">
                    <div className="field col-12">
                        <label htmlFor="idpais">Período Inicial</label><br/>
                        <Calendar
                            value={relatorioFluxoCaixa.periodo_inicial}
                            onChange={(e) => aoDigitar(e, 'periodo_inicial', 'relatorio-fluxo-caixa')}
                            dateFormat="dd/mm/yy"
                        />
                    </div>
                </div>
                <div className="grid formgrid">
                    <div className="field col-12">
                        <label htmlFor="idpais">Período Final</label><br/>
                        <Calendar
                            value={relatorioFluxoCaixa.periodo_final}
                            onChange={(e) => aoDigitar(e, 'periodo_final', 'relatorio-fluxo-caixa')}
                            dateFormat="dd/mm/yy"
                        />
                    </div>
                </div>
            </Dialog>

            <Dialog
                className='fluid'
                header={`Relatório de Caixas - ${relatorioCaixaParamentros.loja.nome}`}
                style={{ maxWidth: '95%', width: '95%' }}
                contentStyle={{ height: '100%', width: '100%' }}
                modal={true}
                visible={exibirRelatorioCaixa}
                onHide={limparTela}
                closeOnEscape={false}
                maximizable
            >
                <Tooltip target=".export-buttons>button" position="bottom" />
                <DataTable value={relatorioCaixaParamentros.dados} header={botoesRelatorioCaixa} tableStyle={{ width: '100%' }} showGridlines scrollable scrollHeight="flex">
                    {relatorioCaixaParamentros.colunas.map((col, index) => {
                        return (
                            <Column
                                key={index}
                                field={col.field}
                                header={col.header}
                                body={(rowData, column) => {
                                    const value = rowData[column.field];
                                    if (column.field === 'balanco') {
                                        const balancoFloat =  parseFloat(
                                            value
                                                .replace(/[^0-9,-]/g, '')
                                                .replace(',', '.')
                                        );
                                        return (
                                            <span style={{ color: balancoFloat < 0 ? 'red' : (balancoFloat > 0 ? 'green' : '#49505') }}>
                                                {value}
                                            </span>
                                        );
                                    }
                                    return value;
                                } }
                            />
                        );
                    })}
                </DataTable>
            </Dialog>

                <Dialog
                    className='fluid'
                    header={`Relatório de Fluxo de Caixas - ${relatorioFluxoCaixaParamentros.loja.nome}`}
                    style={{ maxWidth: '95%', width: '95%' }}
                    contentStyle={{ height: '100%', width: '100%' }}
                    modal={true}
                    visible={exibirRelatorioFluxoCaixa}
                    onHide={limparTela}
                    closeOnEscape={false}
                    maximizable
                >
                    <Tooltip target=".export-buttons>button" position="bottom" />
                    <DataTable value={relatorioFluxoCaixaParamentros.dados} header={botoesRelatorioFluxoCaixa} tableStyle={{ width: '100%' }} showGridlines scrollable scrollHeight="flex">
                        {relatorioFluxoCaixaParamentros.colunas.map((col, index) => {
                            return (
                                <Column
                                    key={index}
                                    field={col.field}
                                    header={col.header}
                                />
                            );
                        })}
                    </DataTable>
            </Dialog>
        </Container>
    );
}

export default Caixa;
