import React, {useEffect, useRef, useState} from "react";
import {Toast}                              from "primereact/toast";
import {InputText}                          from "primereact/inputtext";
import { InputNumber }                      from 'primereact/inputnumber';
import {Button}                             from "primereact/button";
import {confirmDialog}                      from "primereact/confirmdialog";
import {Dialog}                             from "primereact/dialog";
import {Dropdown}                           from "primereact/dropdown";
import { ProgressSpinner }                  from 'primereact/progressspinner';
import {Calendar}                           from "primereact/calendar";

import InputMask from 'react-input-mask';

import api                from "../../services/api";
import {Obrigatorio}      from "../../styles/global";
import Tabela             from "../../components/tabela";
import {parametrosPadrao} from "../../configs/constantes";
import debug              from "../../utils/debug";
import TplStatus          from "../../components/tplStatus";



const Loja = () => {
    const classe = 'Loja';
    const tabela = 'lojas';
    const endpointAPI = 'cadastro/loja';
    const nomeDiaDaSemana = [
        "Domingo",
        "Segunda-Feira",
        "Terça-Feira",
        "Quarta-Feira",
        "Quinta-Feira",
        "Sexta-Feira",
        "Sábado",
    ];
    const aviso = useRef(null);
    const inicial = {id: 0, nome: '', cnpj: '', valor_diario_hora_extra: null};
    const inicialEndereco = {id: 0, idpais: null, idestado: null, idcidade: null, bairro: "", rua: "", numero: null, complemento: null};
    const inicialJornadas = [
        {id: 0, dia: 0, horario_abertura: null, horario_fechamento: null, horas_almoco: null},
        {id: 0, dia: 1, horario_abertura: null, horario_fechamento: null, horas_almoco: null},
        {id: 0, dia: 2, horario_abertura: null, horario_fechamento: null, horas_almoco: null},
        {id: 0, dia: 3, horario_abertura: null, horario_fechamento: null, horas_almoco: null},
        {id: 0, dia: 4, horario_abertura: null, horario_fechamento: null, horas_almoco: null},
        {id: 0, dia: 5, horario_abertura: null, horario_fechamento: null, horas_almoco: null},
        {id: 0, dia: 6, horario_abertura: null, horario_fechamento: null, horas_almoco: null},
    ];
    const [item, setItem] = useState(inicial);
    const [endereco, setEndereco] = useState(inicialEndereco);
    const [jornadas, setJornadas] = useState(inicialJornadas);
    const [tituloTela, setTituloTela] = useState('');
    const [exibeTela, setExibirTela] = useState(false);
    const [listar, setListar] = useState(false);
    const [permissoesTela, setPermissoesTela] = useState(null);
    const [parametrosTela, setParametrosTela] = useState(parametrosPadrao);
    const [desabilitarEstados, setDesabilitarEstados] = useState(true);
    const [desabilitarCidades, setDesabilitarCidades] = useState(true);
    const [paises, setPaises] = useState();
    const [estados, setEstados] = useState();
    const [cidades, setCidades] = useState();
    const [loadingFormulario, setLoadingFormulario] = useState(true);

    useEffect((e) => {
        new api().requisitar('get', `configuracao/tela/loja/permissoes`).then((resposta) => {
            setPermissoesTela(resposta);
            const parametros = {
                pesquisarTabela  : true,
                artigo:            'a',
                nomeClasse       : classe,
                tabela           : tabela,
                caminhoBreadcrumb: [{label: 'Cadastro'}, {label: 'Lojas'}],
                endpointApi      : endpointAPI,
                excluirRegistro  : true,
                tabelaDados      : {
                    reordenar              : false,
                    multiplaSelecao        : true,
                    apresentarDados        : resposta.listar ?? false,
                    botaoAdicionar         : resposta.cadastrar ?? false,
                    botaoExcluir           : resposta.excluir ?? false,
                    botaoEditarRegistro    : resposta.alterar ?? false,
                    botaoExcluirRegistro   : resposta.excluir ?? false,
                    botaoAuditoriaRegistro : resposta.auditoria ?? false,
                    botaoVisualizarRegistro: resposta.visualizar ?? false,
                    filtroStatus           : false, // HABILITA FILTRO DE STATUS
                    colunas                : [
                        {legenda: 'Nome', campo: 'nome', ordenavel: true},
                        {legenda: 'CNPJ', campo: 'cnpj', ordenavel: true},
                        {legenda: 'Valor Fixo (Hora Extra 100%)', campo: 'valor_diario_hora_extra', ordenavel: true},
                        {legenda: 'Status', campo: 'status', ordenavel: true, template: TplStatus}
                    ]
                },
                abrirFormulario  : aoAbrirFormulario,
                finalizarPesquisa: aoListar
            }
            setParametrosTela(parametros);
        }).catch((erro) => {
            throw  {tipo: 'error', titulo: 'Falha!!', mensagem: erro.response.data ?? 'Ocorreu um erro não tratado'};
        });

        new api().requisitar('get', 'precadastro/pais/combo/nome').then((resposta) => {
            setPaises(resposta);
        }).catch((erro) => {
            debug(erro);
        });

    }, []);

    const carregaEstados = (pais) => {
        setDesabilitarEstados(true);
        new api().requisitar('get', `precadastro/estado/combo/${pais}/nome`).then((resposta) => {
            setEstados(resposta);
            setDesabilitarEstados(false);
        }).catch((erro) => {
            debug(erro);
        })
    };

    const carregaCidades = (estado) => {
        setDesabilitarCidades(true);
        new api().requisitar('get', `precadastro/cidade/combo/${estado}`).then((resposta) => {
            setCidades(resposta);
            setDesabilitarCidades(false);
        }).catch((erro) => {
            debug(erro);
        })
    };

    const convertTimeStringToDate = (timeString) => {
        const [hours, minutes, seconds] = timeString.split(':').map(Number);
        const date = new Date();
        date.setHours(hours, minutes, seconds, 0);
        return date;
    };

    const formataJornadas = (jornadas) => {
        return jornadas.map(jornada => ({
            ...jornada,
            horario_abertura: jornada.horario_abertura ? convertTimeStringToDate(jornada.horario_abertura) : null,
            horario_fechamento: jornada.horario_fechamento ? convertTimeStringToDate(jornada.horario_fechamento) : null,
            horas_almoco: jornada.horas_almoco ? convertTimeStringToDate(jornada.horas_almoco) : null,
        }));
    };

    const aoAbrirFormulario = (registro) => {
        setItem(inicial);
        setEndereco(inicialEndereco);
        setJornadas(inicialJornadas);
        setDesabilitarEstados(true);
        setDesabilitarCidades(true);
        setLoadingFormulario(true);
        if (registro.id) {
            new api().requisitar('get', `${endpointAPI}/${registro.id}`).then((resposta) => {
                setEndereco(resposta.endereco);
                carregaEstados(resposta.endereco.idpais);
                carregaCidades(resposta.endereco.idestado);
                const jornadasFormatadas = formataJornadas(resposta.jornadas);
                setJornadas(jornadasFormatadas);
                setItem(resposta);
                setDesabilitarEstados(false);
                setDesabilitarCidades(false);
                setLoadingFormulario(false);
            }).catch((erro) => {
                debug(erro);
            })
            setTituloTela(`Alterar ${classe}`);
        } else {
            setTituloTela(`Cadastro de ${classe}`);
            setLoadingFormulario(false);
        }
        setExibirTela(true);
    };

    const aoListar = () => setListar(false);

    const limparTela = () => {
        setItem(inicial);
        setEndereco(inicialEndereco);
        setJornadas(inicialJornadas);
        setExibirTela(false);
        setListar(true);
    };

    const excluir = async (id) => {
        await new api().requisitar('DELETE', `${endpointAPI}/${id}`).then((resposta) => {
            aviso.current.show({severity: 'success', summary: 'Confirmação', detail: resposta.mensagem, life: 3000});
            limparTela();
        }).catch((erro) => {
            debug(erro);
            aviso.current.show({severity: 'error', summary: 'Erro', detail: (erro.response && erro.response.data && erro.response.data.error_description) ? erro.response.data.error_description : 'Ocorreu um erro não tratado', life: 3000});
        });
    };

    const aoExcluirItem = (id) => {
        confirmDialog({
            message    : `Confirmar exclusão do ${classe}?`,
            header     : 'Atenção',
            icon       : 'pi pi-exclamation-triangle',
            acceptLabel: 'Sim',
            rejectLabel: 'Não',
            accept     : () => {
                excluir(id);
            },
        });
    };

    const aoSalvar = async () => {
        try {
            if (!item.nome) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe o nome!'};
            }
            if (!item.valor_diario_hora_extra) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe o valor fixo diário para horas extras!'};
            }

            if (!endereco.idpais) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe o país!'};
            }
            if (!endereco.idestado) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe o estado!'};
            }
            if (!endereco.idcidade) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe a cidade!'};
            }
            if (!endereco.bairro) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe o bairro!'};
            }
            if (!endereco.rua) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe a rua!'};
            }

            const dadosLoja = {... item};
            dadosLoja.endereco = {... endereco};
            dadosLoja.jornadas = [... jornadas];
            dadosLoja.valor_diario_hora_extra = dadosLoja.valor_diario_hora_extra === '' ? null : dadosLoja.valor_diario_hora_extra;

            await new api().requisitar((item.id > 0) ? 'put' : 'post', (item.id > 0 ? `${endpointAPI}/${item.id ?? ''}` : endpointAPI), dadosLoja).then((resposta) => {
                aviso.current.show({severity: 'success', summary: 'Confirmação', detail: resposta.mensagem, life: 3000});
                limparTela();
            }).catch((erro) => {
                throw  {tipo: 'error', titulo: 'Falha!!', mensagem: erro.response.data ?? 'Ocorreu um erro não tratado'};
            });

        } catch (erro) {
            aviso.current.show({severity: erro.tipo ?? 'error', summary: erro.titulo ?? 'Erro', detail: erro.mensagem ?? erro, life: 3000});
        }
    };

    const aoDigitar = (e, propriedade, elemento, index = null) => {

        let texto = (e.target && e.target.value) || e.value || '';
        if(elemento === 'item'){
            if(propriedade === 'cnpj') {
                texto = texto.replace(/\D/g, '');
            }
            let _item = {...item};
            _item[`${propriedade}`] = texto;
            setItem(_item);
        } else if (elemento === 'endereco'){
            if (propriedade === 'idpais') {
                let _endereco = {...endereco};
                _endereco[`${propriedade}`] = texto;
                _endereco[`idestado`] = null;
                _endereco[`idcidade`] = null;
                setEndereco(_endereco);
                carregaEstados(texto);
            } else if (propriedade === 'idestado') {
                let _endereco = {...endereco};
                _endereco[`${propriedade}`] = texto;
                _endereco[`idcidade`] = null;
                setEndereco(_endereco);
                carregaCidades(texto);
            } else {
                let _endereco = {...endereco};
                _endereco[`${propriedade}`] = texto;
                setEndereco(_endereco);
            }
        } else if (elemento === 'jornadas') {
            const texto = e.value || '';
            let _jornadas = [...jornadas];  // Copiar jornadas como um array
            _jornadas[index][`${propriedade}`] = texto;
            setJornadas(_jornadas);
        }
    };

    const botoesAcaoTela = () => {
        return (<React.Fragment>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={(e) => setExibirTela(false)}/>
            {item.id > 0 && item.status > 0 && permissoesTela.exluir && <Button label="Excluir" icon="pi pi-times" className="p-button-text" onClick={(e) => aoExcluirItem(item.id)}/>}
            {((item.id > 0 && permissoesTela.alterar) || permissoesTela.cadastrar) && <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={aoSalvar}/>}
        </React.Fragment>);
    };

    return (
        <Tabela parametros={parametrosTela} listar={listar}>
            <Toast ref={aviso} />
            <Dialog
                className="fluid"
                header={tituloTela}
                style={{ maxWidth: '90%', width: '800px' }}
                footer={botoesAcaoTela}
                modal
                visible={exibeTela}
                onHide={() => setExibirTela(false)}
            >
                {loadingFormulario ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                        <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                    </div>
                ) : (
                    <>
                        <div className="grid formgrid">
                            <div className="field col-12 mt-3">
                                <label htmlFor="titulo">Nome<Obrigatorio>*</Obrigatorio></label><br />
                                <InputText
                                    className="w-full"
                                    autoComplete="none"
                                    id="nome"
                                    maxLength={200}
                                    value={item.nome}
                                    onChange={(e) => aoDigitar(e, 'nome', 'item')}
                                    required
                                />
                            </div>
                        </div>
                        <div className="grid formgrid">
                            <div className="field col-12 mt-3">
                                <label htmlFor="cnpj">CNPJ<Obrigatorio>*</Obrigatorio></label><br />
                                <InputMask
                                    mask="99.999.999/9999-99"
                                    value={item.cnpj}
                                    onChange={(e) => aoDigitar(e, 'cnpj', 'item')}
                                >
                                    {(inputProps) => <InputText
                                        {...inputProps}
                                        className="w-full"
                                        autoComplete="none"
                                        id="cnpj"
                                        required
                                    />}
                                </InputMask>
                            </div>
                        </div>
                        <div className="grid formgrid">
                            <div className="field col-12 mt-3">
                                <label htmlFor="valor_diario_hora_extra">Valor Diário (Hora Extra 100%)<Obrigatorio>*</Obrigatorio></label><br />
                                <InputNumber
                                    inputId="valor_diario_hora_extra"
                                    value={item.valor_diario_hora_extra}
                                    onChange={(e) => aoDigitar(e, 'valor_diario_hora_extra', 'item')}
                                    mode="currency"
                                    currency="BRL"
                                    locale="pt-BR"
                                />
                            </div>
                        </div>
                        <div className="grid formgrid">
                            <div className="flex col-12 p-0 mt-3">
                                <div className="field col-6">
                                    <label htmlFor="idpais">País<Obrigatorio>*</Obrigatorio></label><br />
                                    <Dropdown
                                        className="w-full"
                                        autoComplete="none"
                                        id="idpais"
                                        value={endereco.idpais}
                                        options={paises}
                                        onChange={(e) => aoDigitar(e, 'idpais', 'endereco')}
                                        optionLabel="valor"
                                        optionValue="id"
                                        required
                                    />
                                </div>
                                <div className="field col-6">
                                    <label htmlFor="idestado">Estado<Obrigatorio>*</Obrigatorio></label><br />
                                    <Dropdown
                                        disabled={desabilitarEstados}
                                        className="w-full"
                                        autoComplete="none"
                                        id="idestado"
                                        value={endereco.idestado}
                                        options={estados}
                                        onChange={(e) => aoDigitar(e, 'idestado', 'endereco')}
                                        optionLabel="valor"
                                        optionValue="id"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="flex col-12 p-0">
                                <div className="field col-6">
                                    <label htmlFor="idcidade">Cidade</label>
                                    <Dropdown
                                        disabled={desabilitarCidades}
                                        className="w-full"
                                        autoComplete="none"
                                        id="idcidade"
                                        value={endereco.idcidade}
                                        options={cidades}
                                        onChange={(e) => aoDigitar(e, 'idcidade', 'endereco')}
                                        optionLabel="valor"
                                        optionValue="id"
                                        required
                                    />
                                </div>
                                <div className="field col-6">
                                    <label htmlFor="bairro">Bairro<Obrigatorio>*</Obrigatorio></label><br />
                                    <InputText
                                        className="w-full"
                                        autoComplete="none"
                                        id="bairro"
                                        maxLength={200}
                                        value={endereco.bairro}
                                        onChange={(e) => aoDigitar(e, 'bairro', 'endereco')}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="flex col-12 p-0">
                                <div className="field col-8">
                                    <label htmlFor="rua">Rua<Obrigatorio>*</Obrigatorio></label><br />
                                    <InputText
                                        className="w-full"
                                        autoComplete="none"
                                        id="rua"
                                        maxLength={200}
                                        value={endereco.rua}
                                        onChange={(e) => aoDigitar(e, 'rua', 'endereco')}
                                        required
                                    />
                                </div>
                                <div className="field col-2">
                                    <label htmlFor="numero">N°<Obrigatorio>*</Obrigatorio></label><br />
                                    <InputNumber
                                        className="w-full"
                                        id="numero"
                                        value={endereco.numero}
                                        onChange={(e) => aoDigitar(e, 'numero', 'endereco')}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="field col-12">
                                <label htmlFor="complemento">Complemento</label><br />
                                <InputText
                                    className="w-full"
                                    autoComplete="complemento"
                                    id="complemento"
                                    maxLength={200}
                                    value={endereco.complemento}
                                    onChange={(e) => aoDigitar(e, 'complemento', 'endereco')}
                                />
                            </div>
                        </div>
                        <div className="grid formgrid">
                            {jornadas ? jornadas.map((jornada, index) => (
                                <div className="flex col-12 mt-3" key={index}>
                                    <div className="field col-3 align-content-end">
                                        <p className="font-bold text-xl">{nomeDiaDaSemana[jornada.dia]}</p>
                                    </div>
                                    <div className="field col-3">
                                        <label htmlFor="horario_abertura">Horário De</label><br />
                                        <Calendar
                                            id="horario_abertura"
                                            value={jornada.horario_abertura}
                                            onChange={(e) => aoDigitar(e, 'horario_abertura', 'jornadas', jornada.dia)}
                                            timeOnly
                                            hourFormat="24"
                                            className="w-full"
                                            showTime
                                        />
                                    </div>
                                    <div className="field col-3">
                                        <label htmlFor="horario_fechamento">Horário Até</label><br />
                                        <Calendar
                                            id="horario_fechamento"
                                            value={jornada.horario_fechamento}
                                            onChange={(e) => aoDigitar(e, 'horario_fechamento', 'jornadas', jornada.dia)}
                                            timeOnly
                                            hourFormat="24"
                                            className="w-full"
                                            showTime
                                        />
                                    </div>
                                    <div className="field col-3">
                                        <label htmlFor="horas_almoco">Horas de Almoço</label><br />
                                        <Calendar
                                            id="horas_almoco"
                                            value={jornada.horas_almoco}
                                            onChange={(e) => aoDigitar(e, 'horas_almoco', 'jornadas', jornada.dia)}
                                            timeOnly
                                            hourFormat="24"
                                            className="w-full"
                                            showTime
                                        />
                                    </div>
                                </div>
                            )) : null}
                        </div>
                    </>
                )}
            </Dialog>
        </Tabela>
    );
}

export default Loja;
