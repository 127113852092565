import React, {useEffect, useRef, useState} from "react";
import {Toast}         from "primereact/toast";
import {Container,Tela}     from "../styles/global";
import api from "../services/api";
import debug from "../utils/debug";
import { Line, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, BarElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import {BreadCrumb} from "primereact/breadcrumb";

ChartJS.register(CategoryScale, LinearScale, PointElement, BarElement, LineElement, Title, Tooltip, Legend);

const Dashboard = () => {
	const caminhoBreadcrumb = [{label: 'Dashboard'}];
	const iconeHome = {icon: 'pi pi-home'};
	const classe = 'Dashboard';
	const endpointAPI = 'dashboard';
	const aviso = useRef(null);

	const [apresentarRelatorios, setApresentarRelatorios] = useState(false);

	const dashboardDadosInicial = { dados: null }
	const [totaisMensais, setTotaisMensais] = useState(dashboardDadosInicial);
	const [totaisPorFormaPagamento, setTotaisPorFormaPagamento] = useState(dashboardDadosInicial);
	const [totaisPorDiaSemana, setTotaisPorDiaSemana] = useState(dashboardDadosInicial);

	const [dadosTotaisMensais, setDadosTotaisMensais] = useState(null);
	const [optionsTotaisMensais, setOptionsTotaisMensais] = useState(null);

	const [dadosFormasPagamento, setDadosFormasPagamento] = useState(null);
	const [optionsFormasPagamento, setOptionsFormasPagamento] = useState(null);

	const [dadosComparativoMes, setDadosComparativoMes] = useState(null);
	const [optionsComparativoMes, setOptionsComparativoMes] = useState(null);

	const [dadosDiaSemana, setDadosDiaSemana] = useState(null);
	const [optionsDiaSemana, setOptionsDiaSemana] = useState(null);

	useEffect((e) => {
		new api().requisitar('get', `configuracao/tela/sistema/permissoes`).then((resposta) => {
			setApresentarRelatorios(resposta.relatorio ?? false);
		}).catch((erro) => {
			throw  {tipo: 'error', titulo: 'Falha!!', mensagem: erro.response.data ?? 'Ocorreu um erro não tratado'};
		});

		new api().requisitar('post', 'relatorios/dashboard/gerar-dashboard/total-mensal').then((resposta) => {
			setTotaisMensais(resposta);
			montaGraficoTotalMensal(resposta);
			montaGraficoComparativoMes(resposta);
		}).catch((erro) => {
			debug(erro);
		});

		new api().requisitar('post', 'relatorios/dashboard/gerar-dashboard/vendas-por-pagamento').then((resposta) => {
			setTotaisPorFormaPagamento(resposta);
			montaGraficoFormaPagamento(resposta);
		}).catch((erro) => {
			debug(erro);
		});


		new api().requisitar('post', 'relatorios/dashboard/gerar-dashboard/vendas-por-dia-semana').then((resposta) => {
			setTotaisPorDiaSemana(resposta);
			montaGraficoDiaSemana(resposta);
		}).catch((erro) => {
			debug(erro);
		});
	}, []);

	const getRandomColor = () => {
		const letters = '0123456789ABCDEF';
		let color = '#';
		for (let i = 0; i < 6; i++) {
			color += letters[Math.floor(Math.random() * 16)];
		}
		return color;
	}

	const montaGraficoTotalMensal = (dados) => {
		const labels = Object.keys(Object.values(dados)[0].meses);
		const lojas = Object.keys(dados).map(key => dados[key]);
		const datasets = lojas.map((loja) => ({
			label: `${loja.nome} -  Valor (R$)`,
			data: Object.values(loja.meses).map((mes) => mes.valor),
			fill: false,
			borderColor: getRandomColor(),
			tension: 0.1,
		}));

		const options = {
			responsive: true,
			plugins: {
				legend: {
					position: 'top',
				},
				title: {
					display: true,
					text: 'Total Faturado Por Loja (Último Ano)',
				},
			},
			scales: {
				x: {
					title: {
						display: true,
						text: 'Mês',
					},
				},
				y: {
					title: {
						display: true,
						text: 'Valor (R$)',
					},
					beginAtZero: true,
				},
			},
		};

		setOptionsTotaisMensais(options);
		setDadosTotaisMensais({
			labels,
			datasets,
		});
	};

	const montaGraficoFormaPagamento = (dados) => {
		const labels = Object.values(dados).map(dado => dado.nome);
		const dataValores = Object.values(dados).map(dado => dado.valor);
		const data = {
			labels: labels,
			datasets: [
				{
					label: 'Valor (R$)',
					data: dataValores,
					backgroundColor: 'rgba(75, 192, 192, 0.6)',
					borderColor: 'rgba(75, 192, 192, 1)',
					borderWidth: 1,
				},
			],
		};

		const options = {
			responsive: true,
			plugins: {
				legend: {
					position: 'top',
				},
				title: {
					display: true,
					text: 'Vendas por Tipo de Pagamento (Último Mês)',
				},
			},
			scales: {
				x: {
					title: {
						display: true,
						text: 'Forma de Pagamento',
					},
				},
				y: {
					title: {
						display: true,
						text: 'Valor (R$)',
					},
					beginAtZero: true,
				},
			},
		};

		setOptionsFormasPagamento(options);
		setDadosFormasPagamento(data);
	};

	const montaGraficoComparativoMes = (dados) => {
		const meses = Object.keys(Object.values(dados)[0].meses);
		const mesAtual = meses[meses.length - 1];
		const mesAnterior = meses[meses.length - 2];

		const lojas = Object.keys(dados).map(key => dados[key]);
		const labels = lojas.map(loja => loja.nome);
		const dadosMesAtual = lojas.map(loja => loja.meses[mesAtual].valor);
		const dadosMesAnterior = lojas.map(loja => loja.meses[mesAnterior].valor);

		const data = {
			labels: labels,
			datasets: [
				{
					label: `Mês Anterior (${mesAnterior})`,
					data: dadosMesAnterior,
					backgroundColor: 'rgba(153, 102, 255, 0.6)',
					borderColor: 'rgba(153, 102, 255, 1)',
					borderWidth: 1,
					barThickness: 10,
				},
				{
					label: `Mês Atual (${mesAtual})`,
					data: dadosMesAtual,
					backgroundColor: 'rgba(75, 192, 192, 0.6)',
					borderColor: 'rgba(75, 192, 192, 1)',
					borderWidth: 1,
					barThickness: 10,
				},
			],
		};

		const options = {
			responsive: true,
			plugins: {
				legend: {
					position: 'top',
				},
				title: {
					display: true,
					text: 'Comparativo de Vendas: Mês Atual vs Mês Anterior',
				},
			},
			scales: {
				x: {
					title: {
						display: true,
						text: 'Loja',
					},
				},
				y: {
					beginAtZero: true,
					title: {
						display: true,
						text: 'Valor (R$)',
					},
				},
			},
		};

		setOptionsComparativoMes(options);
		setDadosComparativoMes(data);
	}

	const montaGraficoDiaSemana = (dados) => {
		const labels = Object.keys(Object.values(dados)[0].dias);
		const lojas = Object.keys(dados).map(key => dados[key]);
		const datasets = lojas.map((loja) => ({
			label: `${loja.nome} -  Valor (R$)`,
			data: Object.values(loja.dias),
			fill: false,
			borderColor: getRandomColor(),
			tension: 0.1,
		}));

		const options = {
			responsive: true,
			plugins: {
				legend: {
					position: 'top',
				},
				title: {
					display: true,
					text: 'Vendas Por Dia da Semana (Último Mês)',
				},
			},
			scales: {
				x: {
					title: {
						display: true,
						text: 'Dia da Semana',
					},
				},
				y: {
					title: {
						display: true,
						text: 'Valor (R$)',
					},
					beginAtZero: true,
				},
			},
		};

		setOptionsDiaSemana(options);
		setDadosDiaSemana({
			labels,
			datasets,
		});
	}
	
	return (
		<Container>
			<Toast ref={aviso}></Toast>
			{
				apresentarRelatorios ? (
					<>
						<BreadCrumb model={[{label: 'Dashboard'}]} home={iconeHome} />
						<Tela>
							{
								totaisMensais && optionsTotaisMensais && dadosTotaisMensais && optionsComparativoMes && dadosComparativoMes &&
								totaisPorFormaPagamento && optionsFormasPagamento && dadosFormasPagamento &&
								totaisPorDiaSemana && optionsDiaSemana && dadosDiaSemana ? (
									<div className="grid formgrid">
										<div className="flex col-12 p-0 mt-3">
											<div className="field col-6">
												<Line data={dadosTotaisMensais} options={optionsTotaisMensais} />
											</div>
											<div className="field col-6">
												<Bar data={dadosComparativoMes} options={optionsComparativoMes} />
											</div>
										</div>
										<div className="flex col-12 p-0 mt-3">
											<div className="field col-6">
												<Bar data={dadosFormasPagamento} options={optionsFormasPagamento}  />
											</div>
											<div className="field col-6">
												<Line data={dadosDiaSemana} options={optionsDiaSemana} />
											</div>
										</div>
									</div>
								) : null
							}
						</Tela>
					</>
				) : null
			}

		</Container>
	);
}

export default Dashboard;
