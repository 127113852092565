import React, {useEffect, useRef, useState} from "react";
import {Toast}                              from "primereact/toast";
import {InputText}                          from "primereact/inputtext";
import {Button}                             from "primereact/button";
import {confirmDialog}                      from "primereact/confirmdialog";
import {Dialog}                             from "primereact/dialog";
import { MultiSelect }                      from 'primereact/multiselect';
import { ProgressSpinner }                  from 'primereact/progressspinner';

import api                from "../../services/api";
import {Obrigatorio}      from "../../styles/global";
import Tabela             from "../../components/tabela";
import {parametrosPadrao} from "../../configs/constantes";
import debug              from "../../utils/debug";

const FormasPagamento = () => {
    const classe = 'Forma de Pagamento';
    const tabela = 'formas_pagamento';
    const endpointAPI = 'vendas/formas-pagamento';
    const aviso = useRef(null);
    const inicial = {id: 0, nome: '', idlojas: []};
    const [item, setItem] = useState(inicial);
    const [tituloTela, setTituloTela] = useState('');
    const [exibeTela, setExibirTela] = useState(false);
    const [listar, setListar] = useState(false);
    const [permissoesTela, setPermissoesTela] = useState(null);
    const [parametrosTela, setParametrosTela] = useState(parametrosPadrao);
    const [lojaLista, setLojaLista] = useState([]);
    const [loadingFormulario, setLoadingFormulario] = useState(true);

    useEffect((e) => {
        new api().requisitar('get', `configuracao/tela/formas-pagamento/permissoes`).then((resposta) => {
            setPermissoesTela(resposta);
            const parametros = {
                pesquisarTabela  : true,
                artigo:            'a',
                nomeClasse       : classe,
                tabela           : tabela,
                caminhoBreadcrumb: [{label: 'Vendas'}, {label: 'Formas de Pagamento'}],
                endpointApi      : endpointAPI,
                excluirRegistro  : true,
                tabelaDados      : {
                    reordenar              : false,
                    multiplaSelecao        : true,
                    apresentarDados        : resposta.listar ?? false,
                    botaoAdicionar         : resposta.cadastrar ?? false,
                    botaoExcluir           : resposta.excluir ?? false,
                    botaoEditarRegistro    : resposta.alterar ?? false,
                    botaoExcluirRegistro   : resposta.excluir ?? false,
                    botaoAuditoriaRegistro : resposta.auditoria ?? false,
                    botaoVisualizarRegistro: resposta.visualizar ?? false,
                    filtroStatus           : false,
                    colunas                : [
                        {legenda: 'Nome', campo: 'nome', ordenavel: true},
                        {legenda: 'Lojas', campo: 'lojas_nome', ordenavel: true},
                    ]
                },
                abrirFormulario  : aoAbrirFormulario,
                finalizarPesquisa: aoListar
            }
            setParametrosTela(parametros);
        }).catch((erro) => {
            throw  {tipo: 'error', titulo: 'Falha!!', mensagem: erro.response.data ?? 'Ocorreu um erro não tratado'};
        });

        new api().requisitar('get', 'cadastro/loja/combo').then((resposta) => {
            setLojaLista(resposta);
        }).catch((erro) => {
            debug(erro);
        });

    }, []);

    const aoAbrirFormulario = (registro) => {
        setItem(inicial);
        setLoadingFormulario(true);
        if (registro.id) {
            new api().requisitar('get', `${endpointAPI}/${registro.id}`).then((resposta) => {
                resposta.idlojas = resposta.idlojas ? resposta.idlojas.map(item => item.idloja) : [];
                setItem(resposta);
                setLoadingFormulario(false);
            }).catch((erro) => {
                debug(erro);
            })
            setTituloTela(`Alterar ${classe}`);
        } else {
            setTituloTela(`Cadastro de ${classe}`);
            setLoadingFormulario(false);
        }
        setExibirTela(true);
    };

    const aoListar = () => setListar(false);

    const limparTela = () => {
        setItem(inicial);
        setExibirTela(false);
        setListar(true);
    };

    const excluir = async (id) => {
        await new api().requisitar('DELETE', `${endpointAPI}/${id}`).then((resposta) => {
            aviso.current.show({severity: 'success', summary: 'Confirmação', detail: resposta.mensagem, life: 3000});
            limparTela();
        }).catch((erro) => {
            debug(erro);
            aviso.current.show({severity: 'error', summary: 'Erro', detail: (erro.response && erro.response.data && erro.response.data.error_description) ? erro.response.data.error_description : 'Ocorreu um erro não tratado', life: 3000});
        });
    };

    const aoExcluirItem = (id) => {
        confirmDialog({
            message    : `Confirmar exclusão do ${classe}?`,
            header     : 'Atenção',
            icon       : 'pi pi-exclamation-triangle',
            acceptLabel: 'Sim',
            rejectLabel: 'Não',
            accept     : () => {
                excluir(id);
            },
        });
    };

    const aoSalvar = async () => {
        try {
            if (!item.nome) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe o nome!'};
            }

            await new api().requisitar((item.id > 0) ? 'put' : 'post', (item.id > 0 ? `${endpointAPI}/${item.id ?? ''}` : endpointAPI), item).then((resposta) => {
                aviso.current.show({severity: 'success', summary: 'Confirmação', detail: resposta.mensagem, life: 3000});
                limparTela();
            }).catch((erro) => {
                throw  {tipo: 'error', titulo: 'Falha!!', mensagem: erro.response.data ?? 'Ocorreu um erro não tratado'};
            });

        } catch (erro) {
            aviso.current.show({severity: erro.tipo ?? 'error', summary: erro.titulo ?? 'Erro', detail: erro.mensagem ?? erro, life: 3000});
        }
    };

    const aoDigitar = (e, propriedade) => {

        const texto = (e.target && e.target.value) || e.value || '';
        let _item = {...item};
        _item[`${propriedade}`] = texto;
        setItem(_item);

    };

    const aoSelecionar = (e, propriedade) => {
        let _item = {...item};
        if (e.checked) {
            _item[`${propriedade}`] = 1;
        }
        else {
            _item[`${propriedade}`] = 0;
        }
        setItem(_item);
    }

    const botoesAcaoTela = () => {
        return (<React.Fragment>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={(e) => setExibirTela(false)}/>
            {item.id > 0 && item.status > 0 && permissoesTela.exluir && <Button label="Excluir" icon="pi pi-times" className="p-button-text" onClick={(e) => aoExcluirItem(item.id)}/>}
            {((item.id > 0 && permissoesTela.alterar) || permissoesTela.cadastrar) && <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={aoSalvar}/>}
        </React.Fragment>);
    };

    return (
        <Tabela parametros={parametrosTela} listar={listar}>
            <Toast ref={aviso}></Toast>
            <Dialog className='fluid' header={tituloTela} style={{maxWidth: '90%', width: '800px'}} footer={botoesAcaoTela} modal={true} visible={exibeTela} onHide={(e) => setExibirTela(false)}>
                {loadingFormulario ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '250px' }}>
                        <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                    </div>
                ) : (
                    <>
                        <div className="grid formgrid">
                            <div className="field col-12 mt-3">
                                <label htmlFor="titulo">Nome<Obrigatorio>*</Obrigatorio> </label><br/>
                                <InputText className="w-full" autoComplete="none" id="nome" maxLength={200} value={item.nome} onChange={(e) => aoDigitar(e, 'nome')} required/>
                            </div>
                        </div>
                        <div className="grid formgrid">
                            <div className="field col-12">
                                <label htmlFor="idlojas">Loja</label><br/>
                                <MultiSelect
                                    className="w-full"
                                    autoComplete="none"
                                    id="idlojas"
                                    value={item.idlojas}
                                    options={lojaLista}
                                    onChange={(e) => aoDigitar(e, 'idlojas')}
                                    optionLabel="valor"
                                    optionValue="id"
                                    required
                                    placeholder="Selecione uma ou mais lojas"
                                />
                            </div>
                        </div>
                    </>
                )}
            </Dialog>
        </Tabela>
    );
}

export default FormasPagamento;
